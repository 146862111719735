import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import styles from './styles';
import { withStyles } from '@material-ui/styles';

import { Notification, translate, userLogin } from 'react-admin';

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({ meta: { touched, error } = {}, input: { ...inputProps }, ...props }) => (
  <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />
);

class MyLoginPage extends Component {
  login = auth => this.props.userLogin(auth, this.props.location.state ? this.props.location.state.nextPathname : '/');

  render() {
    const { classes, handleSubmit, isLoading, translate } = this.props;

    return (
      <div className={classes.contentCenter}>
        <div className={classes.contentForm}>
          <Card>
            <div className={classes.logoContainer} style={{ minHeight: 150 }}>
              <div className={classes.logo} />
            </div>
            <form onSubmit={handleSubmit(this.login)} className={classes.form}>
              <div>
                <div>
                  <Field
                    id="email"
                    name="email"
                    component={renderInput}
                    label={translate('auth.email')}
                    disabled={isLoading}
                  />
                </div>
                <div>
                  <Field
                    id="password"
                    name="password"
                    component={renderInput}
                    label={translate('ra.auth.password')}
                    type="password"
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div>
                <CardActions>
                  <Button variant="contained" type="submit" color="primary" disabled={isLoading} fullWidth>
                    {isLoading && <CircularProgress size={25} thickness={2} />}
                    {translate('ra.auth.sign_in')}
                  </Button>
                </CardActions>
              </div>
            </form>
          </Card>
        </div>
        <Notification />
      </div>
    );
  }
}

MyLoginPage.propTypes = {
  ...propTypes,
  authProvider: PropTypes.func,
  classes: PropTypes.object,
  previousRoute: PropTypes.string,
  translate: PropTypes.func.isRequired,
  userLogin: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
  translate,
  reduxForm({
    form: 'signIn',
    validate: (values, props) => {
      const errors = {};
      const { translate } = props;
      if (!values.username) {
        errors.username = translate('ra.validation.required');
      }
      if (!values.password) {
        errors.password = translate('ra.validation.required');
      }
      return errors;
    }
  }),
  withStyles(styles),
  connect(
    mapStateToProps,
    { userLogin }
  )
);

export default enhance(MyLoginPage);
