import React from 'react';
import { Card, CardHeader, CardActions, Avatar, Button } from '@material-ui/core';
import { PersonOutline } from '@material-ui/icons';
import HomeIcon from '@material-ui/icons/Home';
import { translate, Title } from 'react-admin';
import { withStyles } from '@material-ui/styles';

import { welcomeStyles } from './styles';

const Welcome = translate(
  withStyles(welcomeStyles)(({ classes, translate }) => (
    <Card>
      <Title title={translate('dashboard.title')} />
      <CardHeader
        title={translate('dashboard.welcome.title')}
        subheader={translate('dashboard.welcome.subtitle')}
        avatar={
          <Avatar>
            <PersonOutline />
          </Avatar>
        }
      />
      <CardActions className={classes.actions}>
        <Button
          className={classes.buttons}
          variant="contained"
          href={process.env.MILO_WEB_SITE}
          color="primary"
          target="_blank"
        >
          <HomeIcon />
          {translate('dashboard.welcome.toWebButton')}
        </Button>
      </CardActions>
    </Card>
  ))
);

export default Welcome;
