import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import compose from 'recompose/compose';
import moment from 'moment';
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  NumberField,
  BooleanField,
  UrlField,
  DateField,
  ReferenceField,
  FormDataConsumer,
  fetchStart,
  fetchEnd,
  showNotification,
  UPDATE,
  GET_LIST
} from 'react-admin';

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import MyUserEdit from './MyUserEdit';
import MyLoanEdit from './MyLoanEdit';
import MyHouseEdit from './MyHouseEdit';
import Note from '../../../components/Note';
import CreateNote from '../../../components/CreateNote';
import loopbackRestClient from '../../../services/data-provider';
import { outKeysFromObject } from '../../../utils';

import '../styles.scss';

const { MILO_API_URL } = process.env;
const dataProvider = loopbackRestClient(MILO_API_URL);
const URL_BY_STATUS = [
  {
    name: '/underwriting',
    code: 6
  },
  {
    name: '/closing',
    code: 7
  }
];

const styles = {
  contentButtonNotes: {
    margin: '0 10px'
  },
  emptyNotes: {
    textAlign: 'center',
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10
  }
};

class Component extends React.Component {
  state = {
    loadingFetch: false,
    loadingNotes: false,
    sideBarRight: false,
    notes: []
  };

  componentDidMount() {
    this.loadRemoteNotesByLoanRequest();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const {
      location: { pathname }
    } = nextProps;
    const {
      location: { pathname: oldPathname }
    } = this.props;

    if (pathname !== oldPathname) {
      this.loadRemoteNotesByLoanRequest(pathname);
    }
    return true;
  }

  loadRemoteNotesByLoanRequest = (newPathname = null) => {
    const {
      fetchStart,
      fetchEnd,
      showNotification,
      id,
      location: { pathname: actualPathname }
    } = this.props;
    this.setState({ loadingNotes: true });
    let process = '';
    let pathname = actualPathname;
    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    if (newPathname !== null) {
      pathname = newPathname;
    }

    if (/underwriting\b/g.test(pathname)) {
      process = 'under-writing';
    } else if (/closing\b/g.test(pathname)) {
      process = 'closing';
    } else {
      process = 'review';
    }

    dataProvider(GET_LIST, 'loan-request-notes', {
      filter: {
        loanRequestId: id,
        process
      },
      include: ['autor'],
      pagination: {},
      sort: { field: 'createdAt', order: 'DESC' }
    })
      .then(({ data }) => {
        this.setState({ loadingNotes: false, notes: data });
      })
      .catch(error => {
        this.setState({ loadingNotes: false });
        showNotification(error.message, 'error');
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  handlerNextStep = () => {
    const { fetchStart, fetchEnd, showNotification, dataRecord } = this.props;
    let nextStatusId = 0;

    this.setState({ loadingFetch: true });

    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    if (dataRecord.loanRequestStatusId < 7) {
      nextStatusId = dataRecord.loanRequestStatusId + 1;
    } else {
      nextStatusId = 7;
    }

    dataProvider(UPDATE, 'my-users/update-manual-loan', {
      data: {
        loanId: dataRecord.id,
        houseId: dataRecord.houseId,
        loanPurposeId: dataRecord.loanPurposeId,
        financingConfigurationId: dataRecord.financingConfigurationId,
        loanValue: dataRecord.loanValue,
        loanRequestStatusId: nextStatusId,
        loanOfficerId: dataRecord.loanOfficerId
      }
    })
      .then(({ data }) => {
        this.setState({ loadingFetch: false });
      })
      .catch(error => {
        this.setState({ loadingFetch: false });
        showNotification(error.message, 'error');
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  render() {
    const { loadingFetch, sideBarRight, notes } = this.state;
    const {
      dataRecord,
      location: { pathname },
      classes
    } = this.props;
    const rest = outKeysFromObject(this.props, ['fetchEnd', 'fetchStart', 'showNotification', 'dataRecord', 'hasEdit']);
    const restNote = outKeysFromObject(this.props, ['hasEdit']);
    let redirect = false;
    let redirectStatus = '';
    let process = '';

    if (/underwriting\b/g.test(pathname)) {
      process = 'under-writing';
    } else if (/closing\b/g.test(pathname)) {
      process = 'closing';
    } else {
      process = 'review';
    }

    if (dataRecord.loanRequestStatusId === URL_BY_STATUS[0].code && !/underwriting\b/g.test(pathname)) {
      redirectStatus = URL_BY_STATUS[0].name;
      redirect = true;
    } else if (dataRecord.loanRequestStatusId === URL_BY_STATUS[1].code && !/closing\b/g.test(pathname)) {
      redirectStatus = URL_BY_STATUS[1].name;
      redirect = true;
    }

    if (redirect) {
      redirect = false;
      return <Redirect to={`/loan-requests/${dataRecord.id}/show${redirectStatus}`} />;
    }

    return (
      <>
        <Show
          actions={
            <CardActions>
              {loadingFetch ? (
                <CircularProgress />
              ) : (
                <>
                  <Button variant="outlined" color="primary" onClick={this.handlerNextStep}>
                    <SaveIcon />
                    Continue
                  </Button>
                  <Badge
                    color="primary"
                    badgeContent={Array.isArray(notes) && notes.length > 0 ? notes.length : 0}
                    className={classes.contentButtonNotes}
                  >
                    <Button variant="contained" onClick={() => this.setState({ sideBarRight: true })}>
                      Notes
                    </Button>
                  </Badge>
                </>
              )}
            </CardActions>
          }
          {...rest}
        >
          <TabbedShowLayout>
            <Tab label="Review">
              {/* START USER INFORMATION */}
              <TextField source="borrower_information" className="titleSectionShow" />
              <TextField source="client.firstName" label="FirstName" className="text-field-inline-loan-show" />
              <TextField source="client.lastName" label="lastName" className="text-field-inline-loan-show" />
              <TextField source="client.email" label="email" className="text-field-inline-loan-show" />
              <TextField source="client.uid" label="UID" className="text-field-inline-loan-show" />
              <BooleanField source="client.active" label="Active" className="text-field-inline-loan-show" />
              <BooleanField
                source="client.emailVerified"
                label="Email verified"
                className="text-field-inline-loan-show"
              />
              <TextField source="client.timeZone" label="Time zone" className="text-field-inline-loan-show" />
              <NumberField
                source="client.clientInformation.annualIncome"
                label="Annual income"
                className="text-field-inline-loan-show"
              />
              <DateField
                source="client.clientInformation.birthDate"
                label="BirthDate"
                className="text-field-inline-loan-show"
              />
              <DateField source="client.createdAt" label="Borrow create date" className="text-field-inline-loan-show" />
              <DateField
                source="client.updatedAt"
                label="Borrow last updated"
                className="text-field-inline-loan-show"
              />
              <TextField
                source="client.residenceAddress.address"
                label="Contact address"
                className="text-field-inline-loan-show"
              />
              <TextField
                source="client.residenceAddress.phoneNumber"
                label="Contact phone number"
                className="text-field-inline-loan-show"
              />
              <TextField
                source="client.residenceAddress.zipCode"
                label="Contact zip code"
                className="text-field-inline-loan-show"
              />
              <TextField
                source="client.residenceAddress.city.name"
                label="Contact city"
                className="text-field-inline-loan-show"
              />
              <TextField
                source="client.residenceAddress.state.name"
                label="Contact state"
                className="text-field-inline-loan-show"
              />
              <TextField
                source="client.residenceAddress.country.name"
                label="Contact country"
                className="text-field-inline-loan-show"
              />
              <FormDataConsumer>{({ formData }) => <MyUserEdit values={formData.client} />}</FormDataConsumer>
              {/* END USER INFORMATION */}
              {/* START LOAN INFORMATION */}
              <TextField source="loan_information" className="titleSectionShow" />
              <TextField source="id" className="text-field-inline-loan-show" />
              <NumberField source="loanValue" className="text-field-inline-loan-show" />
              <ReferenceField source="loanPurposeId" reference="loan-purposes" className="text-field-inline-loan-show">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                source="financingConfigurationId"
                reference="financing-configurations"
                label="Financing configuration (months)"
                className="text-field-inline-loan-show"
              >
                <TextField source="monthsNumber" />
              </ReferenceField>
              <ReferenceField
                source="loanRequestStatusId"
                reference="loan-request-statuses"
                className="text-field-inline-loan-show"
              >
                <TextField source="name" />
              </ReferenceField>
              <FormDataConsumer>{({ formData }) => <MyLoanEdit values={formData} />}</FormDataConsumer>
              {/* END LOAN INFORMATION */}
              {/* START PROPERTY INFORMATION */}
              <TextField source="property_information" className="titleSectionShow" />
              <TextField source="house.address" label="Property address" className="text-field-inline-loan-show" />
              <NumberField
                source="house.monthlyRentValue"
                label="Monthly rent value"
                className="text-field-inline-loan-show"
              />
              <NumberField
                source="house.mortgageValue"
                label="Mortgage value"
                className="text-field-inline-loan-show"
              />
              <TextField source="house.neighborhood" label="Neighborhood" className="text-field-inline-loan-show" />
              <NumberField source="house.zipCode" label="ZipCode" className="text-field-inline-loan-show" />
              <TextField source="house.cityName" label="City" className="text-field-inline-loan-show" />
              <TextField source="house.stateName" label="State" className="text-field-inline-loan-show" />
              <BooleanField source="house.rented" label="Rented" className="text-field-inline-loan-show" />
              <TextField
                source="house.propertyTitle.name"
                label="Property title"
                className="text-field-inline-loan-show"
              />
              <TextField
                source="house.propertyType.name"
                label="Property type"
                className="text-field-inline-loan-show"
              />
              <TextField source="house.zillowPid" label="Zillow Pid" className="text-field-inline-loan-show" />
              <TextField source="house.zillowUseCode" label="Zillow use code" className="text-field-inline-loan-show" />
              <UrlField
                source="house.zillowLinkHomeDetails"
                label="Zillow link home details"
                className="text-field-inline-loan-show"
              />
              <DateField
                source="house.zillowLastUpdated"
                label="Zillow Last Updated"
                className="text-field-inline-loan-show"
              />
              <NumberField
                source="house.zillowEstimatedValue"
                label="Zillow estimated value"
                className="text-field-inline-loan-show"
              />
              <NumberField
                source="house.operatedZillowEstimatedValue"
                label="Operated Zillow estimated value"
                className="text-field-inline-loan-show"
              />
              <NumberField
                source="house.zillowLowValuation"
                label="Zillow low valuation"
                className="text-field-inline-loan-show"
              />
              <NumberField
                source="house.zillowHighValuation"
                label="Zillow High Valuation"
                className="text-field-inline-loan-show"
              />
              <NumberField
                source="house.zillowPercentile"
                label="Zillow Percentile"
                className="text-field-inline-loan-show"
              />
              <TextField
                source="house.zillowTaxAssessmentYear"
                label="Zillow tax assessment year"
                className="text-field-inline-loan-show"
              />
              <TextField
                source="house.zillowTaxAssessment"
                label="Zillow tax assessment"
                className="text-field-inline-loan-show"
              />
              <TextField
                source="house.coreLogicEstimated"
                label="Core logic estimated"
                className="text-field-inline-loan-show"
              />
              <FormDataConsumer>{({ formData }) => <MyHouseEdit values={formData.house} />}</FormDataConsumer>
              {/* END LOAN INFORMATION */}
            </Tab>
            <Tab label="Underwriting" path="underwriting">
              <ReferenceField source="loanPurposeId" reference="loan-purposes" className="text-field-inline-loan-show">
                <TextField source="name" />
              </ReferenceField>
            </Tab>
            <Tab label="Closing" path="closing">
              <ReferenceField source="loanPurposeId" reference="loan-purposes" className="text-field-inline-loan-show">
                <TextField source="name" />
              </ReferenceField>
            </Tab>
          </TabbedShowLayout>
        </Show>
        <Drawer anchor="right" open={sideBarRight} onClose={() => this.setState({ sideBarRight: false })}>
          <div>
            <CreateNote
              values={{ process, loanRequestId: dataRecord.id }}
              actionAfterSave={this.loadRemoteNotesByLoanRequest}
            />
            {Array.isArray(notes) && notes.length > 0 ? (
              notes.map((obj, key) => {
                return (
                  <Note
                    key={key}
                    name={obj.title}
                    date={moment(obj.createdAt).format('LL')}
                    comment={obj.description}
                    letter={obj.autor.firstName.slice(0, 1)}
                    {...restNote}
                  />
                );
              })
            ) : (
              <div className={classes.emptyNotes}>
                <Typography component="p">We have not created notes.</Typography>
              </div>
            )}
          </div>
        </Drawer>
      </>
    );
  }
}

Component.propTypes = {
  dataRecord: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    dataFunc: id => {
      if (state.admin.resources['loan-requests'].data[id]) {
        return state.admin.resources['loan-requests'].data[id];
      } else {
        return {};
      }
    }
  };
};

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification
};

const mergeProps = ({ dataFunc }, dispatchProps, ownProps) => {
  return {
    dataRecord: dataFunc(ownProps.match.params.id),
    ...dispatchProps,
    ...ownProps
  };
};

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  dataRecord: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )
)(Component);
