import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField, NumberField, ReferenceField } from 'react-admin';
import ActionList from '../../utils/ActionList';

export const Componet = props => (
  <Show {...props} actions={<ActionList />}>
    <SimpleShowLayout>
      <TextField source="email" type="email" />
      <ReferenceField source="userId" label="First Name" reference="my-users">
        <TextField source="firstName" />
      </ReferenceField>
      <ReferenceField source="userId" label="Last Name" reference="my-users">
        <TextField source="lastName" />
      </ReferenceField>
      <NumberField source="annualIncome" />
      <ReferenceField source="userId" label="Address" reference="houses">
        <TextField source="address" />
      </ReferenceField>
      <ReferenceField source="userId" label="State Name" reference="houses">
        <TextField source="stateName" />
      </ReferenceField>
      <ReferenceField source="userId" label="City Name" reference="houses">
        <TextField source="cityName" />
      </ReferenceField>
      <ReferenceField source="userId" label="Zip Code" reference="houses">
        <TextField source="zipCode" />
      </ReferenceField>
      <DateField source="birthDate" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

export default Componet;
