import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { MenuItemLink, getResources, Responsive, translate, DashboardMenuItem, WithPermissions } from 'react-admin';
import { withStyles } from '@material-ui/styles';
import MemoryIcon from '@material-ui/icons/Memory';
import Divider from '@material-ui/core/Divider';

const { MILO_ROLE_CODE, MILO_ADMIN_ROLE_CODE } = process.env;

const styles = {
  menuLevel: {
    paddingLeft: 16
  },
  menuLevelCollapse: {
    paddingLeft: 16
  }
};

class Menu extends React.Component {
  state = {
    open: true
  };

  setOpen = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  /**
   * Render items of menu by role permissions
   * @param permissions {string}
   * @returns {*}
   */
  renderItemsMenu = permissions => {
    const { resources, onMenuClick, translate } = this.props;
    const modelsWithOutRoleMilo = ['waitlist-status', 'waitlist-reasons', 'property-types', 'loan-purposes'];
    const resourcesFiltered =
      permissions === MILO_ROLE_CODE ? resources.filter(k => ![...modelsWithOutRoleMilo].includes(k.name)) : resources;

    return resourcesFiltered.map(resource => (
      <MenuItemLink
        key={resource.name}
        to={`/${resource.name}`}
        primaryText={translate(`resources.${resource.name}.name`, { smart_count: 3 })}
        leftIcon={createElement(resource.icon)}
        onClick={onMenuClick}
      />
    ));
  };

  render() {
    const { onMenuClick, logout } = this.props;

    return (
      <div>
        <DashboardMenuItem onClick={onMenuClick} />
        <Divider component="hr" light={true} />
        <WithPermissions render={({ permissions }) => this.renderItemsMenu(permissions)} />
        <Divider component="hr" light={true} />
        <WithPermissions
          render={({ permissions }) =>
            permissions === MILO_ADMIN_ROLE_CODE && (
              <MenuItemLink
                to="/configuration"
                primaryText="Configuration"
                leftIcon={<MemoryIcon />}
                onClick={onMenuClick}
              />
            )
          }
        />
        <Responsive
          small={logout}
          medium={logout} // Pass null to render nothing on larger devices
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resources: getResources(state)
});

Menu.propTypes = {
  resources: PropTypes.array,
  menuLoan: PropTypes.array,
  onMenuClick: PropTypes.func,
  logout: PropTypes.object,
  translate: PropTypes.func,
  classes: PropTypes.object
};

export default compose(
  translate,
  withStyles(styles),
  connect(mapStateToProps)
)(Menu);
