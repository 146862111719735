import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-admin';

const ViewTitle = ({ record, translate, resource, field = 'id' }) => {
  return (
    <span>
      {translate(`resources.${resource}.name`, { smart_count: 1 })} {record[field]}
    </span>
  );
};

ViewTitle.propTypes = {
  record: PropTypes.object,
  translate: PropTypes.func,
  resource: PropTypes.string,
  field: PropTypes.string
};

export default translate(ViewTitle);
