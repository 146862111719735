import React from 'react';
import { Create, SimpleForm, required, ReferenceInput, SelectInput, BooleanInput } from 'react-admin';
import ActionList, { redirect } from '../../utils/ActionList';

const Componet = props => (
  <Create {...props} actions={<ActionList />}>
    <SimpleForm submitOnEnter={false} redirect={redirect}>
      <ReferenceInput source="userId" reference="my-users" validate={required()}>
        <SelectInput optionText="email" />
      </ReferenceInput>
      <ReferenceInput source="roleId" reference="my-roles" validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="active" />
    </SimpleForm>
  </Create>
);

export default Componet;
