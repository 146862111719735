import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField, NumberField, ReferenceField } from 'react-admin';
import ActionList from '../../utils/ActionList';

export const Componet = props => (
  <Show {...props} actions={<ActionList />}>
    <SimpleShowLayout>
      <TextField source="email" type="email" />
      <TextField source="firstName" />
      <TextField source="middleName" />
      <TextField source="lastName" />
      <DateField source="birthDate" />
      <NumberField source="annualIncome" />
      <TextField source="state" />
      <TextField source="city" />
      <TextField source="neighborhood" />
      <TextField source="zipcode" />
      <TextField source="address" />
      <ReferenceField source="waitlistReasonId" reference="waitlist-reasons">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="waitlistStatusId" reference="waitlist-status">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

export default Componet;
