import React from 'react';
import { Card, CardHeader, Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import PersonAdd from '@material-ui/icons/PersonAdd';
// import AccountBox from '@material-ui/icons/AccountBox';

import { translate } from 'react-admin';

const styles = {
  card: { borderLeft: 'solid 4px #4caf50', flex: 1, marginLeft: '1em' },
  icon: { float: 'right', width: 64, height: 64, padding: 16, color: '#4caf50' }
};

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default translate(({ visitors = [], nb, translate }) => (
  <Card style={styles.card}>
    <PersonAdd style={styles.icon} />
    <CardHeader title={nb} subheader={translate('dashboard.newUsers')} />
    <List>
      {visitors.map(record => (
        <ListItemLink href={`#/my-users/${record.id}`} key={record.id}>
          <ListItemAvatar>
            <Avatar>
              {record.firstName.substring(0, 1)}
              {record.lastName.substring(0, 1)}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${record.firstName} ${record.lastName}`}
            secondary={new Date(record.createdAt).toLocaleDateString()}
          />
        </ListItemLink>
      ))}
    </List>
  </Card>
));
