import React from 'react';
import {
  Edit,
  SimpleForm,
  DisabledInput,
  TextField,
  EmailField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  NumberField
} from 'react-admin';
import ActionList, { redirect } from '../../utils/ActionList';
import EditToolbar from '../../utils/EditToolbar';

const Component = props => (
  <Edit {...props} actions={<ActionList />}>
    <SimpleForm submitOnEnter={false} redirect={redirect} toolbar={<EditToolbar />}>
      <DisabledInput source="id" label="ID" />
      <EmailField source="email" type="email" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <NumberField source="annualIncome" />
      <ReferenceField source="waitlistReasonId" reference="waitlist-reasons">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceInput source="waitlistStatusId" reference="waitlist-status">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default Component;
