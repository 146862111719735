import React from 'react';
import {
  List,
  Datagrid,
  NumberField,
  DateField,
  Filter,
  TextInput,
  Responsive,
  SimpleList,
  TextField
} from 'react-admin';

const Filters = props => (
  <Filter {...props}>
    <TextInput source="name" />
    <TextInput source="code" />
  </Filter>
);

export const Component = props => (
  <List {...props} filters={<Filters />} bulkActionButtons={false}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.name}
          secondaryText={record => record.code}
          tertiaryText={record => new Date(record.createdAt).toLocaleDateString()}
        />
      }
      medium={
        <Datagrid>
          <NumberField source="id" />
          <TextField source="code" />
          <TextField source="name" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </Datagrid>
      }
    />
  </List>
);

export default Component;
