import React from 'react';
import { Create, SimpleForm, required, ReferenceInput, SelectInput, BooleanInput, TextInput } from 'react-admin';
import ActionList, { redirect } from '../../utils/ActionList';

const Componet = props => (
  <Create {...props} actions={<ActionList />}>
    <SimpleForm submitOnEnter={false} redirect={redirect}>
      <TextInput source="modelName" validate={required()} />
      <TextInput source="httpMethod" validate={required()} />
      <TextInput source="action" />
      <ReferenceInput source="roleId" reference="my-roles" validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="allow" />
    </SimpleForm>
  </Create>
);

export default Componet;
