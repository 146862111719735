import HttpError from './HttpError';

/**
 * function that has the same behavior that httpClient
 *
 * @param {string} url
 * @param {object} [options={}]
 * @returns {Promise} fetch request
 */
export const fetchJson = (url, options = {}, headersOut) => {
  const requestHeaders = new Headers({
    Accept: 'application/json'
  });

  if (!(options && options.body && options.body instanceof FormData)) {
    requestHeaders.set('Content-Type', 'application/json');
  }

  if (!options.token) {
    throw new Error(`there's not token to set.`);
  }

  requestHeaders.set('Authorization', `Bearer ${options.token}`);
  const config = { ...options, headers: requestHeaders };

  return fetch(url, headersOut ? {} : config)
    .then(response =>
      response.text().then(text => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text
      }))
    )
    .then(({ status, statusText, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        return Promise.reject(new HttpError((json && json.message) || statusText, status));
      }
      return { status, headers, body, json };
    });
};

export const queryParameters = data =>
  Object.keys(data)
    .map(key => [key, data[key]].map(encodeURIComponent).join('='))
    .join('&');
