import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { addField, TextInput, required, showNotification } from 'react-admin';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const searchOptions = {
  componentRestrictions: { country: 'USA' }
};

const googleDataLoanTransform = {
  neighborhood: 'neighborhood',
  address: 'address',
  locality: 'city',
  administrative_area_level_3: 'city',
  administrative_area_level_1: 'state',
  administrative_area_level_2: 'county',
  postal_code: 'zipcode',
  street_number: 'street',
  route: 'route'
};

/**
 * reduce places to JSON
 * @param arr
 * @returns {Object}
 */
const reducePlaces = arr => {
  let addressComplete = '';
  return arr.reduce((prev, curr) => {
    const { long_name: shortName, types } = curr;
    const key = googleDataLoanTransform[types[0]];
    if (key) {
      prev[key] = shortName;
    }

    if (key === 'street' || key === 'route') {
      addressComplete = addressComplete.concat(`${shortName} `);
    }

    prev.addressComplete = addressComplete;

    return prev;
  }, {});
};

class SearchAddress extends React.Component {
  state = { search: '', isSelectedAddress: false, valuesToUpdate: {} };

  setSearch = search => this.setState({ search });

  handleSelect = address => {
    const { updateValues, showNotification } = this.props;

    return geocodeByAddress(address)
      .then(([results]) => {
        const add = reducePlaces(
          results.address_components.concat({
            long_name: results.formatted_address.replace(/,.*/, ''),
            types: ['address']
          })
        );
        const LatLng = getLatLng(results);
        const dataUpdate = {
          neighborhood: add.neighborhood || '',
          zipCode: add.zipcode,
          cityName: add.city,
          stateName: add.state
        };

        this.setState({ isSelectedAddress: true, valuesToUpdate: dataUpdate, search: add.addressComplete });
        return LatLng;
      })
      .then(LatLng => {
        const { valuesToUpdate } = this.state;
        return updateValues({ ...valuesToUpdate, ...LatLng });
      })
      .catch(error => {
        this.setState({ isSelectedAddress: false, valuesToUpdate: {} });
        console.error('error handle', error);
        showNotification(error.message, 'error');
      });
  };

  render() {
    const { search } = this.state;

    return (
      <>
        <PlacesAutocomplete
          value={search}
          debounce={500}
          onChange={this.setSearch}
          onSelect={this.handleSelect}
          // shouldFetchSuggestions
          onError={error => {
            // setError({ data: error, type: error })
            // eslint-disable-next-line no-console
            console.log('error', error);
          }}
          googleCallbackName="callbackFunction"
          searchOptions={searchOptions}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <>
              <TextInput
                {...getInputProps({
                  source: 'address'
                })}
                validate={required()}
                defaultValue={search}
              />
              {suggestions.length > 0 && (
                <div>
                  {loading && <div>Loading...</div>}
                  <List>
                    {suggestions.map((suggestion, iter) => (
                      <ListItem key={iter} {...getSuggestionItemProps(suggestion)} button>
                        <ListItemText primary={suggestion.description} />
                      </ListItem>
                    ))}
                  </List>
                </div>
              )}
            </>
          )}
        </PlacesAutocomplete>
      </>
    );
  }
}

SearchAddress.propTypes = {
  updateValues: PropTypes.object.isRequired
};

const mapDispatchToProps = {
  showNotification
};

export default compose(
  addField,
  connect(
    null,
    mapDispatchToProps
  )
)(SearchAddress);
