import React from 'react';
import { Show, TabbedShowLayout, Tab, TextField, DateField, ReferenceField, UrlField, NumberField } from 'react-admin';
import ActionList from '../../utils/ActionList';
const { MILO_ADMIN_ROLE_CODE } = process.env;
export const Componet = ({ permissions, ...props }) => (
  <Show {...props} actions={<ActionList />}>
    <TabbedShowLayout>
      <Tab label="resources.houses.tabs.house">
        <TextField source="address" />
        <TextField source="neighborhood" />
        <TextField source="currency" />
        <ReferenceField source="userId" reference="my-users">
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField source="propertyTypeId" reference="property-types">
          <TextField source="name" />
        </ReferenceField>
        {permissions === MILO_ADMIN_ROLE_CODE ? (
          <ReferenceField source="cityId" reference="cities">
            <TextField source="name" />
          </ReferenceField>
        ) : (
          <TextField source="cityName" />
        )}
        {permissions !== MILO_ADMIN_ROLE_CODE && <TextField source="stateName" />}
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Tab>
      <Tab label="resources.houses.tabs.zillow">
        <TextField source="zillowPid" />
        <TextField source="zillowUseCode" />
        <UrlField source="zillowLinkHomeDetails" target="_blank" />
        <DateField source="zillowLastUpdated" />
        <NumberField source="zillowEstimatedValue" />
        <NumberField source="operatedZillowEstimatedValue" />
        <NumberField source="zillowLowValuation" />
        <NumberField source="zillowHighValuation" />
        <NumberField source="zillowPercentile" />
        <NumberField source="zillowTaxAssessmentYear" />
        <NumberField source="zillowTaxAssessment" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default Componet;
