import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, submit, reset } from 'redux-form';
import {
  Button,
  SaveButton,
  TabbedForm,
  FormTab,
  TextInput,
  NumberInput,
  DateInput,
  SelectInput,
  required,
  fetchEnd,
  fetchStart,
  UPDATE,
  GET_LIST,
  showNotification,
  refreshView
} from 'react-admin';

import IconCancel from '@material-ui/icons/Cancel';
import IconContentEdit from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { timeZones, titleCase } from '../../../utils';
import { formatDate } from '../../../utils/functions';

import loopbackRestClient from '../../../services/data-provider';

const { MILO_API_URL } = process.env;
const dataProvider = loopbackRestClient(MILO_API_URL);

class MyUserEdit extends React.Component {
  state = {
    showDialog: false,
    isSubmitting: false,
    countries: []
  };

  componentDidMount() {
    this.loadCountries();
  }

  loadCountries = () => {
    const { fetchStart, fetchEnd, showNotification } = this.props;

    this.setState({ isSubmitting: true });
    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider(GET_LIST, 'countries-api', { pagination: {}, sort: {}, filter: {} }, true)
      .then(({ data }) => {
        this.setState({ countries: data });
      })
      .catch(error => {
        showNotification(error.message, 'error');
        console.error(error);
      })
      .finally(() => {
        this.setState({ isSubmitting: false });
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  handleSaveClick = () => {
    const { submit } = this.props;

    // Trigger a submit of our custom quick create form
    // This is needed because our modal action buttons are oustide the form
    submit('my-user-quick-edit-button');
  };

  handleSubmit = values => {
    const { fetchStart, fetchEnd, showNotification, reset, refreshView } = this.props;

    this.setState({ isSubmitting: true });
    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider(UPDATE, 'my-users/update-manual-user', {
      data: {
        userId: values.id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        birthDate: formatDate(values.birthDate),
        annualIncome: values.annualIncome,
        timeZone: values.timeZone,
        contactAddress: values.contactAddress,
        contactCity: values.contactCity,
        contactState: values.contactState,
        contactCountry: values.contactCountry,
        contactZipCode: values.contactZipCode,
        contactPhoneNumber: values.contactPhoneNumber
      }
    })
      .then(({ data }) => {
        this.setState({ showDialog: false });
        refreshView();
      })
      .catch(error => {
        showNotification(error.message, 'error');
      })
      .finally(() => {
        this.setState({ isSubmitting: false });
        reset('my-user-quick-edit-button');
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  render() {
    const { showDialog, isSubmitting, countries } = this.state;
    const { values } = this.props;
    let defaultValue = {};

    if (values) {
      defaultValue = {
        ...values,
        birthDate: values.clientInformation.hasOwnProperty('birthDate') ? values.clientInformation.birthDate : '',
        annualIncome: values.clientInformation.hasOwnProperty('annualIncome')
          ? values.clientInformation.annualIncome
          : '',
        contactAddress: values.residenceAddress.hasOwnProperty('address') ? values.residenceAddress.address : '',
        contactPhoneNumber: values.residenceAddress.hasOwnProperty('phoneNumber')
          ? values.residenceAddress.phoneNumber
          : '',
        contactZipCode: values.residenceAddress.hasOwnProperty('zipCode') ? values.residenceAddress.zipCode : '',
        contactCountry:
          values.residenceAddress.hasOwnProperty('country') && values.residenceAddress.country.hasOwnProperty('name')
            ? titleCase(values.residenceAddress.country.name)
            : '',
        contactState:
          values.residenceAddress.hasOwnProperty('state') && values.residenceAddress.state.hasOwnProperty('name')
            ? values.residenceAddress.state.name
            : '',
        contactCity:
          values.residenceAddress.hasOwnProperty('city') && values.residenceAddress.city.hasOwnProperty('name')
            ? values.residenceAddress.city.name
            : ''
      };
    }

    return (
      <>
        <Button onClick={() => this.setState({ showDialog: true })} label="Edit borrower">
          <IconContentEdit />
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={() => this.setState({ showDialog: false })}
          aria-label="Edit borrower"
        >
          <DialogTitle>Edit borrower</DialogTitle>
          <DialogContent>
            <TabbedForm
              onSubmit={this.handleSubmit}
              submitOnEnter={false}
              toolbar={null}
              saving={isSubmitting}
              defaultValue={defaultValue}
              form="my-user-quick-edit-button"
            >
              <FormTab label="Client information">
                <TextInput source="firstName" validate={required()} />
                <TextInput source="lastName" validate={required()} />
                <NumberInput source="annualIncome" validate={required()} />
                <DateInput source="birthDate" validate={required()} />
                <TextInput source="contactAddress" validate={required()} />
                <TextInput source="contactPhoneNumber" validate={required()} />
                <SelectInput
                  source="contactCountry"
                  optionText="name"
                  optionValue="name"
                  choices={countries}
                  translateChoice={false}
                  validate={required()}
                />
                <TextInput source="contactState" validate={required()} />
                <TextInput source="contactCity" validate={required()} />
                <TextInput source="contactZipCode" validate={required()} />
                <SelectInput
                  source="timeZone"
                  optionText="name"
                  optionValue="value"
                  choices={timeZones}
                  translateChoice={false}
                  validate={required()}
                />
                <TextInput source="email" type="email" validate={required()} />
              </FormTab>
            </TabbedForm>
          </DialogContent>
          <DialogActions>
            <SaveButton saving={isSubmitting} onClick={this.handleSaveClick} />
            <Button label="ra.action.cancel" onClick={() => this.setState({ showDialog: false })}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  reset,
  refreshView
};

MyUserEdit.propTypes = {
  submit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  fetchStart: PropTypes.func.isRequired,
  fetchEnd: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  refreshView: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default connect(
  null,
  mapDispatchToProps
)(MyUserEdit);
