import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  Responsive,
  SimpleList,
  ReferenceField,
  EditButton,
  DeleteButton,
  ReferenceInput,
  SelectInput
} from 'react-admin';

const Filters = props => (
  <Filter {...props}>
    <ReferenceInput source="userId" reference="my-users" allowEmpty>
      <SelectInput optionText="email" />
    </ReferenceInput>
    <ReferenceInput source="roleId" reference="my-roles" allowEmpty>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const Component = props => (
  <List {...props} filters={<Filters />} bulkActionButtons={false}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.userId}
          secondaryText={record => record.roleId}
          tertiaryText={record => new Date(record.createdAt).toLocaleDateString()}
        />
      }
      medium={
        <Datagrid>
          <TextField source="id" />
          <ReferenceField source="userId" reference="my-users">
            <TextField source="email" />
          </ReferenceField>
          <ReferenceField source="roleId" reference="my-roles">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
          <EditButton label="" />
          <DeleteButton label="" />
        </Datagrid>
      }
    />
  </List>
);

export default Component;
