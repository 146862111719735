import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  Responsive,
  SimpleList,
  EditButton
} from 'react-admin';

const Filters = props => (
  <Filter {...props}>
    <TextInput source="name" />
    <TextInput source="value" />
    <TextInput source="description" />
  </Filter>
);

export const Component = props => (
  <List {...props} filters={<Filters />} bulkActionButtons={false}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => `${record.firstName} ${record.lastName}`}
          secondaryText={record => record.email}
          tertiaryText={record => new Date(record.createdAt).toLocaleDateString()}
        />
      }
      medium={
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
          <EditButton label="" />
        </Datagrid>
      }
    />
  </List>
);

export default Component;
