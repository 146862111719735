import React from 'react';
import { Edit, SimpleForm, DisabledInput, ReferenceInput, SelectInput, TextField, ReferenceField } from 'react-admin';
import ActionList, { redirect } from '../../utils/ActionList';
import EditToolbar from '../../utils/EditToolbar';

const Component = props => (
  <Edit {...props} actions={<ActionList />}>
    <SimpleForm submitOnEnter={false} redirect={redirect} toolbar={<EditToolbar />}>
      <DisabledInput source="id" label="ID" />
      <ReferenceField source="userId" reference="my-users">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceInput source="roleId" reference="my-roles">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default Component;
