import React from 'react';
import {
  List,
  Edit,
  Datagrid,
  Filter,
  TextInput,
  TextField,
  EmailField,
  BooleanField,
  BooleanInput,
  Toolbar,
  SaveButton,
  DateField,
  Responsive,
  SimpleList,
  SimpleForm
} from 'react-admin';

const Filters = props => (
  <Filter {...props}>
    <TextInput source="fullName" />
    <TextInput source="email" type="email" />
  </Filter>
);

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const ContactEdit = props => (
  <Edit {...props} title=" ">
    <SimpleForm form={`post_contact_${props.id}`} toolbar={<CustomToolbar />}>
      <BooleanInput source="processed" />
    </SimpleForm>
  </Edit>
);

export const Component = props => (
  <List {...props} filters={<Filters />} bulkActionButtons={false}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.fullName}
          secondaryText={record => record.email}
          tertiaryText={record => new Date(record.createdAt).toLocaleDateString()}
        />
      }
      medium={
        <Datagrid expand={<ContactEdit />}>
          <TextField source="id" />
          <TextField source="fullName" />
          <EmailField source="email" />
          <TextField source="phoneNumber" />
          <TextField source="type" />
          <BooleanField source="processed" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </Datagrid>
      }
    />
  </List>
);

export default Component;
