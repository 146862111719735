import React from 'react';
import { Show, TextField, ReferenceField, TabbedShowLayout, Tab } from 'react-admin';

import Tree from '../../components/Tree';

const Component = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Company info">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="phoneNumber" />
        <TextField source="address" />
        <TextField source="zipcode" />
        <ReferenceField source="userId" reference="my-users" label="User">
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField source="cityId" reference="cities">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="stateId" reference="states">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="countryId" reference="countries">
          <TextField source="name" />
        </ReferenceField>
      </Tab>
      <Tab label="Partner's">
        <Tree />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default Component;
