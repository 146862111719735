import React from 'react';
import { Admin, Resource } from 'react-admin';

import MyUsersResource from './resourses/my-users';
import MyRolesResource from './resourses/my-roles';
import AssignedRolesResource from './resourses/assigned-roles';
import InvestorsResource from './resourses/investors';
import ClientsInformationResource from './resourses/clients-information';
import HousesResource from './resourses/houses';
import PropertyTypesResource from './resourses/property-types';
import PropertyTitlesResource from './resourses/property-titles';
import WaitlistReasonsResource from './resourses/waitlist-reasons';
import WaitlistStatusResource from './resourses/waitlist-status';
import WaitlistResource from './resourses/waitlist';
import ContactsResource from './resourses/contacts';
import PermissionsResource from './resourses/permissions';
import ParametersResource from './resourses/parameters';
import CountriesResource from './resourses/countries';
import StatesResource from './resourses/states';
import CitiesResource from './resourses/cities';
import LoanRequestsResource from './resourses/loan-requests';
import LoanPurposesResource from './resourses/loan-purposes';
import LoanTypesResource from './resourses/loan-types';
import LoanRequestStatusesResource from './resourses/loan-request-statuses';
import FinancingConfigurationsResource from './resourses/financing-configurations';
import CompaniesResource from './resourses/companies';
import SubscriptionsResource from './resourses/subscriptions';
import PrequalsResource from './resourses/prequals';

import englishMessages from './i18n/en';
import spanishMessages from './i18n/es';
import loopbackRestClient from './services/data-provider';
import MyLayout from './MyLayout';
import Dashboard from './dashboard/Dashboard';
import authProvider from './services/auth-provider/authProvider';
import customRoutes from './customRoutes';
import Menu from './Menu';
import MyLoginPage from './auth/MyLoginPage';
import './App.css';

const messages = {
  en: englishMessages,
  es: spanishMessages
};

const i18nProvider = locale => messages[locale];

const { MILO_ADMIN_ROLE_CODE, MILO_ROLE_CODE, MILO_API_URL } = process.env;
const dataProvider = loopbackRestClient(MILO_API_URL);
const App = permissions => (
  <Admin
    title="Milo Credit Dashboard"
    appLayout={MyLayout}
    dashboard={Dashboard}
    locale="en"
    menu={Menu}
    customRoutes={customRoutes}
    i18nProvider={i18nProvider}
    dataProvider={dataProvider}
    authProvider={authProvider}
    loginPage={MyLoginPage}
    permissions={permissions}
  >
    {permissions => [
      permissions === MILO_ADMIN_ROLE_CODE && (
        <Resource
          key="0"
          name={MyUsersResource.Name}
          icon={MyUsersResource.Icon}
          create={MyUsersResource.Create}
          list={MyUsersResource.List}
          edit={MyUsersResource.Edit}
        />
      ),
      permissions === MILO_ADMIN_ROLE_CODE && (
        <Resource
          key="1"
          name={MyRolesResource.Name}
          icon={MyRolesResource.Icon}
          create={MyRolesResource.Create}
          list={MyRolesResource.List}
          edit={MyRolesResource.Edit}
        />
      ),
      permissions === MILO_ADMIN_ROLE_CODE && (
        <Resource
          key="2"
          name={AssignedRolesResource.Name}
          icon={AssignedRolesResource.Icon}
          list={AssignedRolesResource.List}
          create={AssignedRolesResource.Create}
          edit={AssignedRolesResource.Edit}
        />
      ),
      permissions === MILO_ADMIN_ROLE_CODE && (
        <Resource
          key="3"
          name={ClientsInformationResource.Name}
          icon={ClientsInformationResource.Icon}
          show={ClientsInformationResource.Show}
          list={ClientsInformationResource.List}
        />
      ),
      (permissions === MILO_ADMIN_ROLE_CODE || permissions === MILO_ROLE_CODE) && (
        <Resource
          key="4"
          name={WaitlistResource.Name}
          list={WaitlistResource.List}
          show={WaitlistResource.Show}
          edit={WaitlistResource.Edit}
          icon={WaitlistResource.Icon}
        />
      ),
      (permissions === MILO_ADMIN_ROLE_CODE || permissions === MILO_ROLE_CODE) && (
        <Resource
          key="5"
          name={WaitlistStatusResource.Name}
          icon={WaitlistStatusResource.Icon}
          list={WaitlistStatusResource.List}
          create={WaitlistStatusResource.Create}
          edit={WaitlistStatusResource.Edit}
        />
      ),
      permissions === MILO_ADMIN_ROLE_CODE && (
        <Resource
          key="6"
          name={HousesResource.Name}
          icon={HousesResource.Icon}
          list={HousesResource.List}
          show={HousesResource.Show}
        />
      ),
      (permissions === MILO_ADMIN_ROLE_CODE || permissions === MILO_ROLE_CODE) && (
        <Resource
          key="7"
          name={PropertyTypesResource.Name}
          icon={PropertyTypesResource.Icon}
          list={PropertyTypesResource.List}
        />
      ),
      (permissions === MILO_ADMIN_ROLE_CODE || permissions === MILO_ROLE_CODE) && (
        <Resource
          key="8"
          name={WaitlistReasonsResource.Name}
          list={WaitlistReasonsResource.List}
          icon={WaitlistReasonsResource.Icon}
        />
      ),
      permissions === MILO_ADMIN_ROLE_CODE && (
        <Resource
          key="9"
          name={PermissionsResource.Name}
          icon={PermissionsResource.Icon}
          create={PermissionsResource.Create}
          list={PermissionsResource.List}
          edit={PermissionsResource.Edit}
        />
      ),
      (permissions === MILO_ADMIN_ROLE_CODE || permissions === MILO_ROLE_CODE) && (
        <Resource key="10" name={ContactsResource.Name} list={ContactsResource.List} icon={ContactsResource.Icon} />
      ),
      permissions === MILO_ADMIN_ROLE_CODE && (
        <Resource
          key="11"
          name={ParametersResource.Name}
          icon={ParametersResource.Icon}
          list={ParametersResource.List}
          create={ParametersResource.Create}
          edit={ParametersResource.Edit}
        />
      ),
      permissions === MILO_ADMIN_ROLE_CODE && (
        <Resource key="12" name={CountriesResource.Name} icon={CountriesResource.Icon} list={CountriesResource.List} />
      ),
      permissions === MILO_ADMIN_ROLE_CODE && (
        <Resource key="13" name={StatesResource.Name} icon={StatesResource.Icon} list={StatesResource.List} />
      ),
      permissions === MILO_ADMIN_ROLE_CODE && (
        <Resource key="14" name={CitiesResource.Name} icon={CitiesResource.Icon} list={CitiesResource.List} />
      ),
      permissions === MILO_ADMIN_ROLE_CODE && (
        <Resource
          key="15"
          name="loan-requests"
          icon={LoanRequestsResource.icon}
          list={LoanRequestsResource.List}
          create={LoanRequestsResource.Create}
          show={LoanRequestsResource.Show}
        />
      ),
      (permissions === MILO_ADMIN_ROLE_CODE || permissions === MILO_ROLE_CODE) && (
        <Resource
          key="16"
          name={LoanPurposesResource.Name}
          icon={LoanPurposesResource.Icon}
          list={LoanPurposesResource.List}
        />
      ),
      permissions === MILO_ADMIN_ROLE_CODE && (
        <Resource key="17" name={LoanTypesResource.Name} icon={LoanTypesResource.Icon} list={LoanTypesResource.List} />
      ),
      permissions === MILO_ADMIN_ROLE_CODE && (
        <Resource
          key="18"
          name={LoanRequestStatusesResource.Name}
          icon={LoanRequestStatusesResource.Icon}
          list={LoanRequestStatusesResource.List}
        />
      ),
      permissions === MILO_ADMIN_ROLE_CODE && (
        <Resource
          key="19"
          name={FinancingConfigurationsResource.Name}
          icon={FinancingConfigurationsResource.Icon}
          list={FinancingConfigurationsResource.List}
        />
      ),
      permissions === MILO_ADMIN_ROLE_CODE && (
        <Resource
          key="20"
          name={PropertyTitlesResource.Name}
          icon={PropertyTitlesResource.Icon}
          list={PropertyTitlesResource.List}
        />
      ),
      permissions === MILO_ADMIN_ROLE_CODE && (
        <Resource
          key="21"
          name={CompaniesResource.Name}
          icon={CompaniesResource.Icon}
          list={CompaniesResource.List}
          edit={CompaniesResource.Edit}
          show={CompaniesResource.Show}
        />
      ),
      (permissions === MILO_ADMIN_ROLE_CODE || permissions === MILO_ROLE_CODE) && (
        <Resource
          key="22"
          name={SubscriptionsResource.Name}
          list={SubscriptionsResource.List}
          icon={SubscriptionsResource.Icon}
        />
      ),
      (permissions === MILO_ADMIN_ROLE_CODE || permissions === MILO_ROLE_CODE) && (
        <Resource key="22" name={PrequalsResource.Name} list={PrequalsResource.List} icon={PrequalsResource.Icon} />
      ),
      permissions === MILO_ADMIN_ROLE_CODE && (
        <Resource
          key="23"
          name={InvestorsResource.Name}
          create={InvestorsResource.Create}
          icon={InvestorsResource.Icon}
          list={InvestorsResource.List}
          edit={InvestorsResource.Edit}
        />
      )
    ]}
  </Admin>
);

export default App;
