/* eslint no-console: 0 */
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import storage from '../../utils/storage';

const encryptPwd = ({ password, ...data }) => ({ ...data, password: btoa(password) });

export default function authProvider(type, params) {
  if (type === AUTH_LOGIN) {
    const request = new Request(`${process.env.MILO_API_URL}/auths/login`, {
      method: 'POST',
      body: JSON.stringify(encryptPwd(params)),
      headers: new Headers({ 'Content-Type': 'application/json' })
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(response => {
        const { myRoles } = response;
        const isAdminstrator = myRoles.find(
          item => item.code === process.env.MILO_ADMIN_ROLE_CODE || item.code === process.env.MILO_ROLE_CODE
        );
        if (!isAdminstrator) {
          throw new Error(`the user ${params.username} is not an Administrator.`);
        }
        storage.save('idAdmin', response.id, 3600);
        storage.save('token', response.accessToken, 3600);
        storage.save('role', isAdminstrator.code, 3600);
      });
  }

  if (type === AUTH_LOGOUT) {
    storage.remove('idAdmin');
    storage.remove('token');
    storage.remove('role');
    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401) {
      storage.remove('idAdmin');
      storage.remove('token');
      return Promise.reject(new Error('There is no token'));
    } else if (status === 403) {
      console.error(`you don't have the permission.`);
    }
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    return storage.load('token') ? Promise.resolve() : Promise.reject(new Error('Check failed'));
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const role = storage.load('role');
    return role ? Promise.resolve(role) : Promise.reject(new Error('Get Permissions failed'));
  }
  return Promise.reject(new Error('Unknown method'));
}
