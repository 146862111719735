import React, { Component } from 'react';
import {
  Create,
  TextInput,
  required,
  TabbedForm,
  FormTab,
  NumberInput,
  SelectInput,
  TextField,
  DisabledInput,
  minLength,
  maxLength,
  email,
  regex,
  choices,
  SimpleForm
} from 'react-admin';
import {
  countryList,
  states,
  propertyType,
  loanPurpose,
  timeZones,
  financingConfiguration,
  OptionsHouse
} from '../../utils/';
import ActionList, { redirect } from '../../utils/ActionList';
import { withStyles } from '@material-ui/styles';
import './styles.scss';

const { MILO_ROLE_CODE } = process.env;
const styles = {
  inlineBlock: { display: 'inline-flex', margin: '0px 1rem 1rem 2rem', width: '350px' }
};

const validateFirstName = [required(), minLength(2), maxLength(15)];
const validateLastName = [required(), minLength(2), maxLength(25)];
const validateEmail = email();
const validateZipCode = required();
const validateBirthDay = regex(
  /^(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])(\/)\d{4}$/i,
  'Must be a valid date, the correct format is mm/dd/yyyy'
);

const validatePropertyType = choices([2], 'Must be Second home');

class LoanCreateForm extends Component {
  state = {
    User: { timeZone: 'America/New_York', state: 'Florida', loanPurposeId: 1, propertyTypeId: 2 },
    IsApartment: true
  };

  render() {
    const { classes, permissions } = this.props;
    const { User, IsApartment } = this.state;
    return (
      <Create {...this.props} actions={<ActionList />}>
        {permissions === MILO_ROLE_CODE ? (
          <SimpleForm submitOnEnter={false} redirect={redirect}>
            <TextInput source="firstName" validate={required()} />
            <TextInput source="middleName" />
            <TextInput source="lastName" validate={required()} />
            <TextInput source="email" type="email" validate={required()} />
            <TextInput source="password" type="password" validate={required()} />
          </SimpleForm>
        ) : (
          <TabbedForm defaultValue={User}>
            <FormTab label="User">
              <TextField source="client_information" className="titleSection" />
              <TextInput source="firstName" validate={validateFirstName} formClassName={classes.inlineBlock} />
              <TextInput source="lastName" validate={validateLastName} formClassName={classes.inlineBlock} />
              <NumberInput source="annualIncome" validate={required()} formClassName={classes.inlineBlock} />
              <TextInput source="email" type="email" validate={validateEmail} formClassName={classes.inlineBlock} />
              <TextInput
                source="birthDate"
                type="email"
                validate={validateBirthDay}
                formClassName={classes.inlineBlock}
              />
              <SelectInput
                source="timeZone"
                optionText="name"
                optionValue="value"
                choices={timeZones}
                translateChoice={false}
                formClassName={classes.inlineBlock}
                validate={required()}
              />
              <TextField source="loan_address_information" className="titleSection" />
              <SelectInput
                source="state"
                optionText="name"
                optionValue="name"
                choices={states}
                translateChoice={false}
                formClassName={classes.inlineBlock}
                validate={required()}
              />
              <TextInput source="city" validate={required()} formClassName={classes.inlineBlock} />
              <TextInput source="addressComplete" validate={required()} formClassName={classes.inlineBlock} />
              <SelectInput
                source="IsApartment"
                optionText="name"
                optionValue="value"
                defaultValue={true}
                choices={OptionsHouse}
                translateChoice={false}
                validate={required()}
                onChange={key => this.setState({ IsApartment: key })}
                formClassName={classes.inlineBlock}
              />
              {IsApartment ? (
                <NumberInput validate={required()} source="secondary" formClassName={classes.inlineBlock} />
              ) : (
                <DisabledInput source="secondary" formClassName={classes.inlineBlock} />
              )}

              <TextInput source="zipcode" validate={validateZipCode} formClassName={classes.inlineBlock} />
              <SelectInput
                source="propertyTypeId"
                optionText="name"
                optionValue="id"
                choices={propertyType}
                translateChoice={false}
                validate={validatePropertyType}
                formClassName={classes.inlineBlock}
              />
              <SelectInput
                source="loanPurposeId"
                optionText="name"
                optionValue="id"
                choices={loanPurpose}
                translateChoice={false}
                validate={required()}
                formClassName={classes.inlineBlock}
              />
              <TextField source="client_contact_information" className="titleSection" />
              <SelectInput
                source="contactCountry"
                optionText="name"
                optionValue="name"
                choices={countryList}
                translateChoice={false}
                defaultValue={'United States'}
                validate={required()}
                formClassName={classes.inlineBlock}
              />
              <TextInput source="contactState" validate={required()} formClassName={classes.inlineBlock} />
              <TextInput source="contactCity" validate={required()} formClassName={classes.inlineBlock} />
              <TextInput source="contactAddress" validate={required()} formClassName={classes.inlineBlock} />
              <TextInput source="contactZipCode" validate={validateZipCode} formClassName={classes.inlineBlock} />
              <TextInput source="contactPhoneNumber" validate={required()} formClassName={classes.inlineBlock} />
              <TextField source="loan_information" className="titleSection" />
              <NumberInput source="loanValue" validate={required()} formClassName={classes.inlineBlock} />
              <SelectInput
                source="financingConfigurationId"
                optionText="name"
                optionValue="value"
                choices={financingConfiguration}
                translateChoice={false}
                defaultValue={1}
                validate={required()}
                formClassName={classes.inlineBlock}
              />
            </FormTab>
          </TabbedForm>
        )}
      </Create>
    );
  }
}

export default withStyles(styles)(LoanCreateForm);
