import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = {
  grid: {
    marginTop: '10px'
  },
  card: {
    minWidth: 275,
    maxWidth: 500
  }
};

function SimpleCard(props) {
  const { classes } = props;

  return (
    <Grid className={classes.grid} container direction="row" justify="center" alignItems="center">
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" component="h2">
            You cant do this!
          </Typography>
          <Typography component="p">Well... you dont have the permission to do this.</Typography>
        </CardContent>
        <CardActions>
          <Button size="small" href="/">
            Go to the dashboard
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleCard);
