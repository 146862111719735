import React from 'react';
import {
  List,
  Datagrid,
  NumberField,
  DateField,
  Filter,
  TextInput,
  Responsive,
  SimpleList,
  TextField,
  ReferenceField,
  ReferenceInput,
  SelectInput
} from 'react-admin';

const Filters = props => (
  <Filter {...props}>
    <TextInput source="name" />
    <ReferenceInput source="stateId" reference="states">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const Component = props => (
  <List {...props} filters={<Filters />} bulkActionButtons={false}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.name}
          secondaryText={record => record.stateId}
          tertiaryText={record => new Date(record.createdAt).toLocaleDateString()}
        />
      }
      medium={
        <Datagrid>
          <NumberField source="id" />
          <TextField source="name" />
          <ReferenceField source="stateId" reference="states">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </Datagrid>
      }
    />
  </List>
);

export default Component;
