import React from 'react';
import { ListButton, CardActions } from 'react-admin';
import PropTypes from 'prop-types';

const ActionList = ({ basePath }) => (
  <CardActions>
    <ListButton basePath={basePath} />
  </CardActions>
);

export const redirect = basePath => basePath;

redirect.propTypes = { basePath: PropTypes.string.isRequired };
ActionList.propTypes = { basePath: PropTypes.string };

export default ActionList;
