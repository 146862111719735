const styles = {
  buttonCreateNote: {
    margin: '2%',
    width: '95%'
  },
  containerButtonsActionsForm: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    marginTop: 20
  }
};

export default styles;
