import React from 'react';
import { List, Datagrid, NumberField, DateField, EmailField, Responsive, SimpleList, DeleteButton } from 'react-admin';

export const Component = props => (
  <List {...props} bulkActionButtons={false}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.email}
          secondaryText={record => new Date(record.createdAt).toLocaleDateString()}
          tertiaryText={record => new Date(record.updatedAt).toLocaleDateString()}
        />
      }
      medium={
        <Datagrid>
          <NumberField source="id" />
          <EmailField source="email" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
          <DeleteButton label="" />
        </Datagrid>
      }
    />
  </List>
);

export default Component;
