import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, submit, isSubmitting } from 'redux-form';
import {
  fetchEnd,
  fetchStart,
  required,
  showNotification,
  Button,
  SaveButton,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  CREATE,
  REDUX_FORM_NAME
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import SearchAddress from '../../../components/SearchAddress';

import loopbackRestClient from '../../../services/data-provider';
const { MILO_API_URL } = process.env;
const dataProvider = loopbackRestClient(MILO_API_URL);

class HousesQuickCreateButton extends Component {
  state = {
    error: false,
    showDialog: false,
    formValues: {
      neighborhood: '',
      zipCode: '',
      cityName: '',
      stateName: '',
      lng: '',
      lat: ''
    }
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleSaveClick = () => {
    const { submit } = this.props;

    // Trigger a submit of our custom quick create form
    // This is needed because our modal action buttons are oustide the form
    submit('houses-quick-create-button');
  };

  handleSubmit = values => {
    const { change, fetchStart, fetchEnd, showNotification } = this.props;

    if (values.hasOwnProperty('secondary') && values.secondary !== '') {
      values.address = `${values.address}, ${values.secondary}`;
    }

    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider(CREATE, 'my-users/manual-house-create', { data: values })
      .then(({ data }) => {
        // Update the main react-admin form (in this case, the comments creation form)
        change(REDUX_FORM_NAME, 'houseId', data.id);
      })
      .catch(error => {
        showNotification(error.message, 'error');
      })
      .finally(() => {
        this.setState({ showDialog: false });
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  updateValuesWithAddress = data => this.setState({ formValues: data });

  render() {
    const { showDialog, formValues } = this.state;
    const { isSubmitting, defaultValue } = this.props;

    return (
      <Fragment>
        <Button onClick={this.handleClick} label="ra.action.create">
          <IconContentAdd />
        </Button>
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Create house">
          <DialogTitle>Create property address</DialogTitle>
          <DialogContent>
            <SimpleForm
              form="houses-quick-create-button"
              resource="houses"
              onSubmit={this.handleSubmit}
              toolbar={null}
              defaultValue={defaultValue}
            >
              <SearchAddress updateValues={this.updateValuesWithAddress} />
              <TextInput source="secondary" label="Apartment / Unit" />
              <ReferenceInput source="propertyTypeId" reference="property-types" validate={required()}>
                <SelectInput optionText="name" optionValue="id" />
              </ReferenceInput>
              <TextInput source="neighborhood" validate={required()} defaultValue={formValues.neighborhood} />
              <NumberInput source="zipcode" validate={required()} defaultValue={formValues.zipCode} />
              <TextInput source="city" validate={required()} defaultValue={formValues.cityName} />
              <TextInput source="state" validate={required()} defaultValue={formValues.stateName} />
              <TextInput source="lng" validate={required()} defaultValue={formValues.lng} />
              <TextInput source="lat" validate={required()} defaultValue={formValues.lat} />
              <ReferenceInput source="userId" reference="my-users" validate={required()}>
                <SelectInput optionText="email" optionValue="id" disabled />
              </ReferenceInput>
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton saving={isSubmitting} onClick={this.handleSaveClick} />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting('houses-quick-create-button')(state)
});

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

HousesQuickCreateButton.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  fetchStart: PropTypes.func.isRequired,
  fetchEnd: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  defaultValue: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HousesQuickCreateButton);
