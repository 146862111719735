import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Responsive,
  EmailField,
  SimpleList,
  EditButton,
  DeleteButton
} from 'react-admin';

export const Component = props => (
  <List {...props} bulkActionButtons={false}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => `${record.firstName} ${record.lastName}`}
          secondaryText={record => record.email}
          tertiaryText={record => new Date(record.createdAt).toLocaleDateString()}
        />
      }
      medium={
        <Datagrid>
          <TextField source="id" />
          <EmailField source="email" type="email" />
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="companyName" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
          <EditButton label="" />
          <DeleteButton label="" />
        </Datagrid>
      }
    />
  </List>
);

export default Component;
