import React from 'react';
import { Edit, SimpleForm, ReferenceInput, SelectInput, TextInput, Title, BooleanInput, required } from 'react-admin';
import ActionList, { redirect } from '../../utils/ActionList';
import EditToolbar from '../../utils/EditToolbar';

const Component = props => (
  <Edit title={<Title resource={props.resource} />} {...props} actions={<ActionList />}>
    <SimpleForm submitOnEnter={false} redirect={redirect} toolbar={<EditToolbar />}>
      <TextInput source="modelName" validate={required()} />
      <TextInput source="httpMethod" validate={required()} />
      <TextInput source="action" />
      <ReferenceInput source="roleId" reference="my-roles" validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="allow" />
    </SimpleForm>
  </Edit>
);

export default Component;
