import React from 'react';
import { Route } from 'react-router-dom';

import NoAccess from './components/no-access';
import Configuration from './configuration/Configuration';

export default [
  <Route key="no-access" exact path="/no-access" component={NoAccess} noLayout />,
  <Route key="configuration" exact path="/configuration" component={Configuration} />
];
