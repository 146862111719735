import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit, reset } from 'redux-form';
import compose from 'recompose/compose';
import {
  SimpleForm,
  TextInput,
  LongTextInput,
  required,
  SaveButton,
  fetchStart,
  fetchEnd,
  showNotification,
  CREATE
} from 'react-admin';

import Button from '@material-ui/core/Button';
import { Add } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

import storage from '../../utils/storage';
import loopbackRestClient from '../../services/data-provider';
import styles from './styles';

const { MILO_API_URL } = process.env;
const dataProvider = loopbackRestClient(MILO_API_URL);

class CreateNote extends React.Component {
  state = {
    isSubmitting: false,
    isCreating: false
  };

  handleSaveClick = () => {
    const { submit } = this.props;

    // Trigger a submit of our custom quick create form
    // This is needed because our modal action buttons are oustide the form
    submit('loan-request-note-create');
  };

  handleSubmit = values => {
    const { values: valuesFromProps, fetchStart, fetchEnd, showNotification, actionAfterSave, reset } = this.props;

    this.setState({ isSubmitting: true });

    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    dataProvider(CREATE, 'loan-request-notes', {
      data: { ...values, ...valuesFromProps, autorId: storage.load('idAdmin') || null }
    })
      .then(({ data }) => {
        this.setState({ isSubmitting: false });
      })
      .catch(error => {
        this.setState({ isSubmitting: false, isCreating: false });
        showNotification(error.message, 'error');
      })
      .finally(() => {
        reset('loan-request-note-create');
        actionAfterSave();
        this.setState({ isCreating: false });
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  render() {
    const { isSubmitting, isCreating } = this.state;
    const { reset, classes } = this.props;

    if (!isCreating) {
      return (
        <Button
          className={classes.buttonCreateNote}
          variant="contained"
          color="primary"
          onClick={() => this.setState({ isCreating: true })}
        >
          <Add /> Create Note
        </Button>
      );
    }

    return (
      <SimpleForm
        form="loan-request-note-create"
        onSubmit={this.handleSubmit}
        submitOnEnter={false}
        saving={isSubmitting}
        toolbar={null}
      >
        <TextInput source="title" validate={required()} />
        <LongTextInput source="description" validate={required()} resettable />
        <div className={classes.containerButtonsActionsForm}>
          <SaveButton saving={isSubmitting} onClick={this.handleSaveClick} />
          <Button
            onClick={() => {
              this.setState({ isCreating: false });
              reset('loan-request-note-create');
            }}
          >
            Cancel
          </Button>
        </div>
      </SimpleForm>
    );
  }
}

const mapDispatchToProps = {
  submit,
  fetchEnd,
  fetchStart,
  showNotification,
  reset
};

CreateNote.propTypes = {
  submit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  fetchStart: PropTypes.func.isRequired,
  fetchEnd: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  actionAfterSave: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  )
)(CreateNote);
