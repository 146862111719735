import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  Responsive,
  SimpleList,
  EmailField,
  ReferenceManyField,
  ReferenceField,
  SingleFieldList,
  ChipField,
  EditButton
} from 'react-admin';
const { MILO_ROLE_CODE } = process.env;
const Filters = props => (
  <Filter {...props}>
    <TextInput source="email" type="email" />
    <TextInput source="firstName" />
    <TextInput source="lastName" />
  </Filter>
);

export const Component = ({ permissions, ...props }) => (
  <List {...props} filters={<Filters />} bulkActionButtons={false}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => `${record.firstName} ${record.lastName}`}
          secondaryText={record => record.email}
          tertiaryText={record => new Date(record.createdAt).toLocaleDateString()}
        />
      }
      medium={
        permissions !== MILO_ROLE_CODE ? (
          <Datagrid>
            <TextField source="id" />
            <EmailField source="email" type="email" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="authUid" />
            <ReferenceManyField label="Assigned roles" reference="assigned-roles" target="userId">
              <SingleFieldList>
                <ChipField source="myRole.code" />
              </SingleFieldList>
            </ReferenceManyField>
            <EditButton label="" />
          </Datagrid>
        ) : (
          <Datagrid>
            <TextField source="id" />
            <EmailField source="email" type="email" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="clientLoanRequests[0].uid" label="Loan ID" />
            <ReferenceField source="houses[0].id" label="Address" linkType="show" reference="houses">
              <TextField source="address" />
            </ReferenceField>
            <ReferenceField source="houses[0].id" label="zipCode" sortBy="zipCode" reference="houses">
              <TextField source="zipCode" />
            </ReferenceField>
            <TextField source="residenceAddress.phoneNumber" label="Phone" />
            <EditButton label="" />
          </Datagrid>
        )
      }
    />
  </List>
);

export default Component;
