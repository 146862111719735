import englishMessages from 'ra-language-english';

export default {
  ...englishMessages,
  dashboard: {
    title: 'Milo Credit Administrator',
    createloan: 'Create loan',
    welcome: {
      title: 'Welcome!',
      subtitle: `This is the admin of the Milo Credit platform. Please be careful about the things that you do here!`,
      toWebButton: 'Milo Credit web site'
    },
    newUsers: 'New users'
  },
  customRoutes: {
    myAccount: 'My account',
    configurations: {
      menuName: 'Configurations',
      name: 'Configurations',
      language: {
        title: 'Language',
        subtitle: 'Select the language that you want.'
      }
    }
  },
  auth: {
    email: 'Email address'
  },
  resources: {
    contacts: {
      name: 'Contact |||| Contacts',
      fields: {
        id: 'ID',
        fullName: 'Full name',
        email: 'Email address',
        phoneNumber: 'Phone number',
        type: 'Type',
        processed: 'Processed',
        createdAt: 'Created at',
        updatedAt: 'Updated at'
      }
    },
    'waitlist-reasons': {
      name: 'Waitlist reason |||| Waitlist reasons',
      fields: {
        id: 'ID',
        code: 'Code',
        name: 'Name',
        createdAt: 'Created at',
        updatedAt: 'Updated at'
      }
    },
    'waitlist-status': {
      name: 'Waitlist status |||| Waitlist statuses',
      fields: {
        id: 'ID',
        name: 'Name',
        createdAt: 'Created at',
        updatedAt: 'Updated at'
      }
    },
    waitlist: {
      name: 'Waitlist |||| Waitlist',
      fields: {
        id: 'ID',
        email: 'Email address',
        firstName: 'First name',
        middleName: 'Middle name',
        lastName: 'Last name',
        birthDate: 'Birth date',
        annualIncome: 'Annual income',
        state: 'State',
        city: 'City',
        neighborhood: 'Neighborhood',
        zipcode: 'Zip code',
        address: 'Address',
        processed: 'Processed',
        waitlistReasonId: 'Reason',
        waitlistStatusId: 'Status',
        createdAt: 'Created at',
        updatedAt: 'Updated at'
      }
    },
    'my-users': {
      name: 'User |||| Users',
      fields: {
        id: 'ID',
        email: 'Email address',
        client_information: 'Client Information',
        loan_address_information: 'Loan Address Information',
        client_contact_information: 'Client Contact Information',
        loan_information: 'Loan Information',
        firstName: 'First name',
        middleName: 'Middle name',
        lastName: 'Last name',
        authUid: 'Authentification ID',
        active: 'Active',
        annualIncome: 'Annual Income',
        'clientInformation.annualIncome': 'Annual Income',
        birthDate: 'Birthdate (mm/dd/yyyy)',
        'clientInformation.birthDate': 'Birthdate',
        timeZone: 'TimeZone',
        state: 'State',
        'houses[0].stateName': 'State',
        city: 'City',
        'houses[0].cityName': 'City',
        'houses[0].address': 'Addresss',
        addressComplete: 'Addresss',
        secondary: 'Apartment / Unit',
        zipcode: 'Zip Code',
        'houses[0].zipCode': 'Zip Code',
        propertyTypeId: 'Property type',
        'houses[0].propertyTypeId': 'Property type',
        loanPurposeId: 'Loan purpose',
        'clientLoanRequests[0].loanPurposeId': 'Loan purpose',
        contactCountry: 'Contact country',
        contactState: 'Contact state',
        contactCity: 'Contact city',
        contactAddress: 'Contact address',
        contactZipCode: 'Contact zip code',
        contactPhoneNumber: 'Contact Phone',
        loanValue: 'Loan value',
        financingConfigurationId: 'Financing configuration',
        'clientLoanRequests[0].financingConfigurationId': 'Financing configuration',
        'clientLoanRequests[0].loanValue': 'Loan value',
        'passport.passportImageUrl': 'Passport Image',
        'passport.visaImageUrl': 'Visa Image',
        'clientLoanRequests[0].generatedDocuments[0].url': 'Document Fee Estimate',
        createdAt: 'Created at',
        updatedAt: 'Updated at'
      }
    },
    'my-roles': {
      name: 'Role |||| Roles',
      fields: {
        id: 'ID',
        code: 'Code',
        name: 'Name',
        updatedAt: 'Updated at',
        processed: 'Processed'
      }
    },
    'assigned-roles': {
      name: 'Assigned role |||| Assigned roles',
      fields: {
        id: 'ID',
        roleId: 'Role',
        userId: 'User',
        updatedAt: 'Updated at',
        processed: 'Processed'
      }
    },
    permissions: {
      name: 'Permission |||| Permissions',
      fields: {
        id: 'ID',
        modelName: 'Model name',
        httpMethod: 'HTTP method',
        action: 'Action',
        allow: 'Allow',
        roleId: 'Role',
        updatedAt: 'Updated at',
        processed: 'Processed'
      }
    },
    'clients-information': {
      name: 'Client information |||| Clients information',
      fields: {
        id: 'ID',
        DNI: 'DNI',
        birthDate: 'Birth date',
        annualIncome: 'Anual income',
        userId: 'User',
        createdAt: 'Created at',
        updatedAt: 'Updated at'
      }
    },
    'property-types': {
      name: 'Property type |||| Property types',
      fields: {
        id: 'ID',
        code: 'Code',
        name: 'Name',
        createdAt: 'Created at',
        updatedAt: 'Updated at'
      }
    },
    houses: {
      name: 'House |||| Houses',
      fields: {
        id: 'ID',
        address: 'Address',
        neighborhood: 'Neighborhood',
        zillowPid: 'Zillow PID',
        zillowUseCode: 'Zillow use code',
        zillowLinkHomeDetails: 'Zillow link home details',
        zillowLastUpdated: 'Zillow last updated',
        zillowEstimatedValue: 'Zillow estimated value',
        operatedZillowEstimatedValue: 'Operated zillow estimated value',
        zillowLowValuation: 'Zillow low valuation',
        zillowHighValuation: 'Zillow high valuation',
        zillowPercentile: 'Zillow percentile',
        zillowTaxAssessmentYear: 'Zillow tax assessment year',
        zillowTaxAssessment: 'Zillow tax assessment',
        cityId: 'City',
        propertyTypeId: 'Property type',
        userId: 'User',
        createdAt: 'Created at',
        updatedAt: 'Updated at'
      },
      tabs: {
        house: 'House',
        zillow: 'Zillow'
      }
    },
    parameters: {
      name: 'Parameter |||| Parameters',
      fields: {
        id: 'ID',
        name: 'Name',
        value: 'Value',
        description: 'Description',
        createdAt: 'Created at',
        updatedAt: 'Updated at'
      }
    },
    countries: {
      name: 'Country |||| Countries',
      fields: {
        id: 'ID',
        name: 'Name',
        createdAt: 'Created at',
        updatedAt: 'Updated at'
      }
    },
    states: {
      name: 'State |||| States',
      fields: {
        id: 'ID',
        name: 'Name',
        countryId: 'Country',
        createdAt: 'Created at',
        updatedAt: 'Updated at'
      }
    },
    cities: {
      name: 'City |||| Cities',
      fields: {
        id: 'ID',
        name: 'Name',
        stateId: 'State',
        createdAt: 'Created at',
        updatedAt: 'Updated at'
      }
    },
    'loan-requests': {
      name: 'Loans requests'
    },
    'loan-purposes': {
      name: 'Loan purposes'
    },
    'financing-configurations': {
      name: 'Financing config'
    },
    'loan-request-statuses': {
      name: 'Loan request statuses'
    },
    'loan-types': {
      name: 'Loan types'
    },
    'property-titles': {
      name: 'Property titles'
    },
    companies: {
      name: 'Companies'
    },
    investors: {
      name: 'Investors'
    },
    subscriptions: {
      name: 'Subscriptions'
    },
    prequals: {
      name: 'Prequals'
    }
  }
};
