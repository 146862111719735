const styles = {
  contentCenter: {
    height: '100vh',
    width: '100%',
    position: 'relative'
  },
  contentForm: {
    width: '300px',
    margin: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  form: {
    padding: '20px'
  },
  logo: {
    backgroundImage: `url(https://static.milocredit.com/static/logo_owl_milo_credit.png)`,
    width: '250px',
    height: '200px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    margin: 'auto'
  }
};

export default styles;
