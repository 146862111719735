import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

const styles = {
  avatar: {
    margin: 10
  },
  contentNote: {
    maxWidth: 345,
    margin: 12
  }
};

const Note = ({ classes, name, date, comment, letter }) => (
  <Card className={classes.contentNote}>
    <CardHeader avatar={<Avatar className={classes.avatar}>{letter}</Avatar>} title={name} subheader={date} />
    <CardContent>
      <Typography variant="body2" color="textSecondary" component="p">
        {comment}
      </Typography>
    </CardContent>
  </Card>
);

Note.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  letter: PropTypes.string.isRequired
};

export default withStyles(styles)(Note);
