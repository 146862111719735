import React from 'react';
import { UserMenu, MenuItemLink, translate } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import PropTypes from 'prop-types';

const MyUserMenu = ({ translate }) => (
  <UserMenu>
    <MenuItemLink to="/my-account" primaryText={translate(`customRoutes.myAccount`)} leftIcon={<SettingsIcon />} />
  </UserMenu>
);

MyUserMenu.propTypes = {
  translate: PropTypes.func,
  logout: PropTypes.object
};

export default translate(MyUserMenu);
