import React from 'react';
import {
  List,
  Datagrid,
  NumberField,
  DateField,
  Responsive,
  SimpleList,
  TextField,
  EditButton,
  ShowButton
} from 'react-admin';

export const Component = ({ permissions, ...props }) => (
  <List {...props} bulkActionButtons={false}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.id}
          secondaryText={record => record.name}
          tertiaryText={record => new Date(record.createdAt).toLocaleDateString()}
        />
      }
      medium={
        <Datagrid>
          <NumberField source="id" />
          <TextField source="name" />
          <TextField source="phoneNumber" />
          <TextField source="address" />
          <TextField source="zipcode" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
          <ShowButton label="" />
          <EditButton label="" />
        </Datagrid>
      }
    />
  </List>
);

export default Component;
