import { queryParameters, fetchJson } from './fetch';
import { GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE, CREATE, UPDATE, DELETE, DELETE_MANY } from './types';
import getFormDataObject from '../../utils/get-form-data-object';
import resourcesWithFile from './resources-with-file';
import storage from '../../utils/storage';
const { MILO_ROLE_CODE } = process.env;
const apiCountries = 'https://restcountries.eu/rest/v2/all?fields=name';
/**
 * Maps react-admin queries to a loopback powered REST API
 *
 * @see https://github.com/strongloop/loopback
 * @example
 * GET_LIST     => GET http://my.api.url/posts?filter[sort]="title ASC"&filter[skip]=0&filter[limit]=20
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter[where][or]=[{id:123},{id:456}]
 * UPDATE       => PATCH http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 * DELETE_MANY  => DELETE http://my.api.url/posts?filter[where][or]=[{id:123},{id:456}]
 */
export default (apiUrl, httpClient = fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertRESTRequestToHTTP = (type, resource, params) => {
    resource = resource.toLowerCase();
    let url = '';
    const options = {};

    const token = storage.load('token');
    const permissions = storage.load('role');
    if (token) {
      options.token = token;
    }

    switch (type) {
      case GET_LIST: {
        const { page = null, perPage = null } = params.pagination;
        const { field, order } = params.sort;
        const query = {};
        let filters = {};
        for (let [key, value] of Object.entries(params.filter)) {
          filters[key] = { like: `%${value}%` };
        }
        query['where'] = { ...filters };
        if (field) query['order'] = [field + ' ' + order];
        if (perPage > 0) {
          query['limit'] = perPage;
          if (page >= 0) {
            query['skip'] = (page - 1) * perPage;
          }
        }
        if (resource === 'my-users') {
          query['include'] = ['clientInformation', 'clientLoanRequests', 'houses', 'residenceAddress'];
          url = `${apiUrl}/${resource}?${queryParameters({
            filter: JSON.stringify(query)
          })}`;
        } else if (resource === 'assigned-roles') {
          query['include'] = ['myUser'];
          url = `${apiUrl}/${resource}?${queryParameters({
            filter: JSON.stringify(query)
          })}`;
        } else if (resource === 'countries-api') {
          options.method = 'GET';
          url = `${apiCountries}`;
        } else {
          query['include'] = params.include;
          url = `${apiUrl}/${resource}?${queryParameters({ filter: JSON.stringify(query) })}`;
        }
        break;
      }
      case GET_ONE:
        if (resource === 'my-users') {
          url = `${apiUrl}/${resource}/${
            params.id
          }?filter={"include":["clientInformation","houses","residenceAddress","passport",{"relation":"clientLoanRequests","scope":{"include":["financingConfiguration","generatedDocuments"]}}]}`;
        } else if (resource === 'loan-requests') {
          url = `${apiUrl}/${resource}/${
            params.id
          }?filter={"include":[{"relation":"house","scope":{"include":["propertyTitle","propertyType"]}},"house",{"relation":"client","scope":{"include":["clientInformation",{"relation":"residenceAddress","scope":{"include":["city","state","country"]}}]}}]}`;
        } else {
          url = `${apiUrl}/${resource}/${params.id}`;
        }
        break;
      case GET_MANY: {
        const query = {};
        const listId = params.ids.map(id => {
          return { id: id };
        });
        if (resource === 'assigned-roles') {
          query['include'] = ['myUser'];
          query['where'] = { or: listId };
          url = `${apiUrl}/${resource}?${queryParameters({
            filter: JSON.stringify(query)
          })}`;
        } else {
          const query = {
            where: { or: listId }
          };
          url = `${apiUrl}/${resource}?${queryParameters({ filter: JSON.stringify(query) })}`;
        }
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {};
        query['where'] = { ...params.filter };
        query['where'][params.target] = params.id;
        if (field) query['order'] = [field + ' ' + order];
        if (perPage > 0) {
          query['limit'] = perPage;
          if (page >= 0) {
            query['skip'] = (page - 1) * perPage;
          }
        }
        url = `${apiUrl}/${resource}?${queryParameters({ filter: JSON.stringify(query) })}`;
        break;
      }
      case UPDATE:
        options.method = 'PATCH';
        if (
          resource === 'my-users' &&
          ((params.data.hasOwnProperty('passportImage') && params.data.passportImage.hasOwnProperty('rawFile')) ||
            (params.data.hasOwnProperty('visaImage') && params.data.visaImage.hasOwnProperty('rawFile')) ||
            (params.data.hasOwnProperty('feeEstimate') && params.data.feeEstimate.hasOwnProperty('rawFile'))) &&
          permissions === MILO_ROLE_CODE
        ) {
          options.headers = 'Content-Type:multipart/form-data';
          url = `${apiUrl}/loan-requests/upload-users-files`;
          params.data.loanRequestId = params.data.clientLoanRequests[0].id;
          const formData = getFormDataObject(params.data);
          options.body = formData;
        } else if (resource === 'my-users/update-manual-user') {
          options.headers = 'Content-Type:multipart/form-data';
          url = `${apiUrl}/${resource}`;
          options.body = getFormDataObject(params.data);
        } else if (resourcesWithFile.includes(resource)) {
          options.headers = 'Content-Type:multipart/form-data';
          url = `${apiUrl}/${resource}/${params.id}/update-with-file`;
          const formData = getFormDataObject(params.data);
          options.body = formData;
        } else {
          url = `${apiUrl}/${resource}${params.id ? '/' + params.id : ''}`;
          options.body = JSON.stringify(params.data);
        }
        break;
      case CREATE:
        options.method = 'POST';
        if (resource === 'my-users/manual-admin-create') {
          url = `${apiUrl}/${resource}`;
          options.body = getFormDataObject(params.data);
        } else if (resourcesWithFile.includes(resource)) {
          url = `${apiUrl}/${resource}/create-with-file`;
          options.body = getFormDataObject(params.data);
        } else {
          url = `${apiUrl}/${resource}`;
          options.body = JSON.stringify(params.data);
        }
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
      case DELETE_MANY:
        const id = params.ids[0];
        url = `${apiUrl}/${resource}/${id}`;
        options.method = 'DELETE';
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} REST response
   */
  const convertHTTPResponseToREST = (response, type, resource, params) => {
    const { headers, json } = response;
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        const response = {
          data: json
        };

        if (!headers.has('x-total-count')) {
          // eslint-disable-next-line no-unreachable
          console.warn(
            'The X-Total-Count header is missing in the HTTP Response. The jsonServer REST client expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
          );
        } else {
          response.total = parseInt(
            headers
              .get('x-total-count')
              .split('/')
              .pop(),
            10
          );
        }
        return response;
      case CREATE:
        return { data: { ...params.data, id: json.id } };
      case DELETE:
        return { data: { id: params.id } };
      default:
        return { data: json };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a REST response
   */
  return (type, resource, params, headersOut = false) => {
    const { url, options } = convertRESTRequestToHTTP(type, resource, params);
    return httpClient(url, options, headersOut)
      .then(response => convertHTTPResponseToREST(response, type, resource, params))
      .catch(e => console.warn(e));
  };
};
