export const countryList = [
  { name: 'Afghanistan' },
  { name: 'Åland Islands' },
  { name: 'Albania' },
  { name: 'Algeria' },
  { name: 'American Samoa' },
  { name: 'AndorrA' },
  { name: 'Angola' },
  { name: 'Anguilla' },
  { name: 'Antarctica' },
  { name: 'Antigua and Barbuda' },
  { name: 'Argentina' },
  { name: 'Armenia' },
  { name: 'Aruba' },
  { name: 'Australia' },
  { name: 'Austria' },
  { name: 'Azerbaijan' },
  { name: 'Bahamas' },
  { name: 'Bahrain' },
  { name: 'Bangladesh' },
  { name: 'Barbados' },
  { name: 'Belarus' },
  { name: 'Belgium' },
  { name: 'Belize' },
  { name: 'Benin' },
  { name: 'Bermuda' },
  { name: 'Bhutan' },
  { name: 'Bolivia' },
  { name: 'Bosnia and Herzegovina' },
  { name: 'Botswana' },
  { name: 'Bouvet Island' },
  { name: 'Brazil' },
  { name: 'British Indian Ocean Territory' },
  { name: 'Brunei Darussalam' },
  { name: 'Bulgaria' },
  { name: 'Burkina Faso' },
  { name: 'Burundi' },
  { name: 'Cambodia' },
  { name: 'Cameroon' },
  { name: 'Canada' },
  { name: 'Cape Verde' },
  { name: 'Cayman Islands' },
  { name: 'Central African Republic' },
  { name: 'Chad' },
  { name: 'Chile' },
  { name: 'China' },
  { name: 'Christmas Island' },
  { name: 'Cocos (Keeling) Islands' },
  { name: 'Colombia' },
  { name: 'Comoros' },
  { name: 'Congo' },
  { name: 'Congo, The Democratic Republic of the' },
  { name: 'Cook Islands' },
  { name: 'Costa Rica' },
  { name: "Cote D'Ivoire" },
  { name: 'Croatia' },
  { name: 'Cuba' },
  { name: 'Cyprus' },
  { name: 'Czech Republic' },
  { name: 'Denmark' },
  { name: 'Djibouti' },
  { name: 'Dominica' },
  { name: 'Dominican Republic' },
  { name: 'Ecuador' },
  { name: 'Egypt' },
  { name: 'El Salvador' },
  { name: 'Equatorial Guinea' },
  { name: 'Eritrea' },
  { name: 'Estonia' },
  { name: 'Ethiopia' },
  { name: 'Falkland Islands (Malvinas)' },
  { name: 'Faroe Islands' },
  { name: 'Fiji' },
  { name: 'Finland' },
  { name: 'France' },
  { name: 'French Guiana' },
  { name: 'French Polynesia' },
  { name: 'French Southern Territories' },
  { name: 'Gabon' },
  { name: 'Gambia' },
  { name: 'Georgia' },
  { name: 'Germany' },
  { name: 'Ghana' },
  { name: 'Gibraltar' },
  { name: 'Greece' },
  { name: 'Greenland' },
  { name: 'Grenada' },
  { name: 'Guadeloupe' },
  { name: 'Guam' },
  { name: 'Guatemala' },
  { name: 'Guernsey' },
  { name: 'Guinea' },
  { name: 'Guinea-Bissau' },
  { name: 'Guyana' },
  { name: 'Haiti' },
  { name: 'Heard Island and Mcdonald Islands' },
  { name: 'Holy See (Vatican City State)' },
  { name: 'Honduras' },
  { name: 'Hong Kong' },
  { name: 'Hungary' },
  { name: 'Iceland' },
  { name: 'India' },
  { name: 'Indonesia' },
  { name: 'Iran, Islamic Republic Of' },
  { name: 'Iraq' },
  { name: 'Ireland' },
  { name: 'Isle of Man' },
  { name: 'Israel' },
  { name: 'Italy' },
  { name: 'Jamaica' },
  { name: 'Japan' },
  { name: 'Jersey' },
  { name: 'Jordan' },
  { name: 'Kazakhstan' },
  { name: 'Kenya' },
  { name: 'Kiribati' },
  { name: "Korea, Democratic People'S Republic of" },
  { name: 'Korea, Republic of' },
  { name: 'Kuwait' },
  { name: 'Kyrgyzstan' },
  { name: "Lao People'S Democratic Republic" },
  { name: 'Latvia' },
  { name: 'Lebanon' },
  { name: 'Lesotho' },
  { name: 'Liberia' },
  { name: 'Libyan Arab Jamahiriya' },
  { name: 'Liechtenstein' },
  { name: 'Lithuania' },
  { name: 'Luxembourg' },
  { name: 'Macao' },
  { name: 'Macedonia, The Former Yugoslav Republic of' },
  { name: 'Madagascar' },
  { name: 'Malawi' },
  { name: 'Malaysia' },
  { name: 'Maldives' },
  { name: 'Mali' },
  { name: 'Malta' },
  { name: 'Marshall Islands' },
  { name: 'Martinique' },
  { name: 'Mauritania' },
  { name: 'Mauritius' },
  { name: 'Mayotte' },
  { name: 'Mexico' },
  { name: 'Micronesia, Federated States of' },
  { name: 'Moldova, Republic of' },
  { name: 'Monaco' },
  { name: 'Mongolia' },
  { name: 'Montserrat' },
  { name: 'Morocco' },
  { name: 'Mozambique' },
  { name: 'Myanmar' },
  { name: 'Namibia' },
  { name: 'Nauru' },
  { name: 'Nepal' },
  { name: 'Netherlands' },
  { name: 'Netherlands Antilles' },
  { name: 'New Caledonia' },
  { name: 'New Zealand' },
  { name: 'Nicaragua' },
  { name: 'Niger' },
  { name: 'Nigeria' },
  { name: 'Niue' },
  { name: 'Norfolk Island' },
  { name: 'Northern Mariana Islands' },
  { name: 'Norway' },
  { name: 'Oman' },
  { name: 'Pakistan' },
  { name: 'Palau' },
  { name: 'Palestinian Territory, Occupied' },
  { name: 'Panama' },
  { name: 'Papua New Guinea' },
  { name: 'Paraguay' },
  { name: 'Peru' },
  { name: 'Philippines' },
  { name: 'Pitcairn' },
  { name: 'Poland' },
  { name: 'Portugal' },
  { name: 'Puerto Rico' },
  { name: 'Qatar' },
  { name: 'Reunion' },
  { name: 'Romania' },
  { name: 'Russian Federation' },
  { name: 'RWANDA' },
  { name: 'Saint Helena' },
  { name: 'Saint Kitts and Nevis' },
  { name: 'Saint Lucia' },
  { name: 'Saint Pierre and Miquelon' },
  { name: 'Saint Vincent and the Grenadines' },
  { name: 'Samoa' },
  { name: 'San Marino' },
  { name: 'Sao Tome and Principe' },
  { name: 'Saudi Arabia' },
  { name: 'Senegal' },
  { name: 'Serbia and Montenegro' },
  { name: 'Seychelles' },
  { name: 'Sierra Leone' },
  { name: 'Singapore' },
  { name: 'Slovakia' },
  { name: 'Slovenia' },
  { name: 'Solomon Islands' },
  { name: 'Somalia' },
  { name: 'South Africa' },
  { name: 'South Georgia and the South Sandwich Islands' },
  { name: 'Spain' },
  { name: 'Sri Lanka' },
  { name: 'Sudan' },
  { name: 'Suriname' },
  { name: 'Svalbard and Jan Mayen' },
  { name: 'Swaziland' },
  { name: 'Sweden' },
  { name: 'Switzerland' },
  { name: 'Syrian Arab Republic' },
  { name: 'Taiwan, Province of China' },
  { name: 'Tajikistan' },
  { name: 'Tanzania, United Republic of' },
  { name: 'Thailand' },
  { name: 'Timor-Leste' },
  { name: 'Togo' },
  { name: 'Tokelau' },
  { name: 'Tonga' },
  { name: 'Trinidad and Tobago' },
  { name: 'Tunisia' },
  { name: 'Turkey' },
  { name: 'Turkmenistan' },
  { name: 'Turks and Caicos Islands' },
  { name: 'Tuvalu' },
  { name: 'Uganda' },
  { name: 'Ukraine' },
  { name: 'United Arab Emirates' },
  { name: 'United Kingdom' },
  { name: 'United States' },
  { name: 'United States Minor Outlying Islands' },
  { name: 'Uruguay' },
  { name: 'Uzbekistan' },
  { name: 'Vanuatu' },
  { name: 'Venezuela' },
  { name: 'Viet Nam' },
  { name: 'Virgin Islands, British' },
  { name: 'Virgin Islands, U.S.' },
  { name: 'Wallis and Futuna' },
  { name: 'Western Sahara' },
  { name: 'Yemen' },
  { name: 'Zambia' },
  { name: 'Zimbabwe' }
];
export const states = [
  { name: 'Alabama' },
  { name: 'Alaska' },
  { name: 'American Samoa' },
  { name: 'Arizona' },
  { name: 'Arkansas' },
  { name: 'California' },
  { name: 'Colorado' },
  { name: 'Connecticut' },
  { name: 'Delaware' },
  { name: 'District Of Columbia' },
  { name: 'Federated States Of Micronesia' },
  { name: 'Florida' },
  { name: 'Georgia' },
  { name: 'Guam' },
  { name: 'Hawaii' },
  { name: 'Idaho' },
  { name: 'Illinois' },
  { name: 'Indiana' },
  { name: 'Iowa' },
  { name: 'Kansas' },
  { name: 'Kentucky' },
  { name: 'Louisiana' },
  { name: 'Maine' },
  { name: 'Marshall Islands' },
  { name: 'Maryland' },
  { name: 'Massachusetts' },
  { name: 'Michigan' },
  { name: 'Minnesota' },
  { name: 'Mississippi' },
  { name: 'Missouri' },
  { name: 'Montana' },
  { name: 'Nebraska' },
  { name: 'Nevada' },
  { name: 'New Hampshire' },
  { name: 'New Jersey' },
  { name: 'New Mexico' },
  { name: 'New York' },
  { name: 'North Carolina' },
  { name: 'North Dakota' },
  { name: 'Northern Mariana Islands' },
  { name: 'Ohio' },
  { name: 'Oklahoma' },
  { name: 'Oregon' },
  { name: 'Palau' },
  { name: 'Pennsylvania' },
  { name: 'Puerto Rico' },
  { name: 'Rhode Island' },
  { name: 'South Carolina' },
  { name: 'South Dakota' },
  { name: 'Tennessee' },
  { name: 'Texas' },
  { name: 'Utah' },
  { name: 'Vermont' },
  { name: 'Virgin Islands' },
  { name: 'Virginia' },
  { name: 'Washington' },
  { name: 'West Virginia' },
  { name: 'Wisconsin' },
  { name: 'Wyoming' }
];

export const propertyType = [
  { id: 1, name: 'PRIMARY RESIDENCE' },
  { id: 2, name: 'SECOND HOME' },
  { id: 3, name: 'INVESTMENT PROPERTY' }
];

export const loanPurpose = [
  { id: 1, name: 'GREAT INVESTMENT' },
  { id: 2, name: 'BUSINESS NEED' },
  { id: 3, name: 'BUY PROPERTY' }
];
export const timeZones = [
  { value: 'Pacific/Niue', name: '(GMT-11:00) Niue' },
  { value: 'Pacific/Pago_Pago', name: '(GMT-11:00) Pago Pago' },
  { value: 'Pacific/Honolulu', name: '(GMT-10:00) Hawaii Time' },
  { value: 'Pacific/Rarotonga', name: '(GMT-10:00) Rarotonga' },
  { value: 'Pacific/Tahiti', name: '(GMT-10:00) Tahiti' },
  { value: 'Pacific/Marquesas', name: '(GMT-09:30) Marquesas' },
  { value: 'America/Anchorage', name: '(GMT-09:00) Alaska Time' },
  { value: 'Pacific/Gambier', name: '(GMT-09:00) Gambier' },
  { value: 'America/Los_Angeles', name: '(GMT-08:00) Pacific Time' },
  { value: 'America/Tijuana', name: '(GMT-08:00) Pacific Time - Tijuana' },
  { value: 'America/Vancouver', name: '(GMT-08:00) Pacific Time - Vancouver' },
  { value: 'America/Whitehorse', name: '(GMT-08:00) Pacific Time - Whitehorse' },
  { value: 'Pacific/Pitcairn', name: '(GMT-08:00) Pitcairn' },
  { value: 'America/Dawson_Creek', name: '(GMT-07:00) Mountain Time - Dawson Creek' },
  { value: 'America/Denver', name: '(GMT-07:00) Mountain Time' },
  { value: 'America/Edmonton', name: '(GMT-07:00) Mountain Time - Edmonton' },
  { value: 'America/Hermosillo', name: '(GMT-07:00) Mountain Time - Hermosillo' },
  { value: 'America/Mazatlan', name: '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan' },
  { value: 'America/Phoenix', name: '(GMT-07:00) Mountain Time - Arizona' },
  { value: 'America/Yellowknife', name: '(GMT-07:00) Mountain Time - Yellowknife' },
  { value: 'America/Belize', name: '(GMT-06:00) Belize' },
  { value: 'America/Chicago', name: '(GMT-06:00) Central Time' },
  { value: 'America/Costa_Rica', name: '(GMT-06:00) Costa Rica' },
  { value: 'America/El_Salvador', name: '(GMT-06:00) El Salvador' },
  { value: 'America/Guatemala', name: '(GMT-06:00) Guatemala' },
  { value: 'America/Managua', name: '(GMT-06:00) Managua' },
  { value: 'America/Mexico_City', name: '(GMT-06:00) Central Time - Mexico City' },
  { value: 'America/Regina', name: '(GMT-06:00) Central Time - Regina' },
  { value: 'America/Tegucigalpa', name: '(GMT-06:00) Central Time - Tegucigalpa' },
  { value: 'America/Winnipeg', name: '(GMT-06:00) Central Time - Winnipeg' },
  { value: 'Pacific/Galapagos', name: '(GMT-06:00) Galapagos' },
  { value: 'America/Bogota', name: '(GMT-05:00) Bogota' },
  { value: 'America/Cancun', name: '(GMT-05:00) America Cancun' },
  { value: 'America/Cayman', name: '(GMT-05:00) Cayman' },
  { value: 'America/Guayaquil', name: '(GMT-05:00) Guayaquil' },
  { value: 'America/Havana', name: '(GMT-05:00) Havana' },
  { value: 'America/Iqaluit', name: '(GMT-05:00) Eastern Time - Iqaluit' },
  { value: 'America/Jamaica', name: '(GMT-05:00) Jamaica' },
  { value: 'America/Lima', name: '(GMT-05:00) Lima' },
  { value: 'America/Nassau', name: '(GMT-05:00) Nassau' },
  { value: 'America/New_York', name: '(GMT-05:00) Eastern Time' },
  { value: 'America/Panama', name: '(GMT-05:00) Panama' },
  { value: 'America/Port-au-Prince', name: '(GMT-05:00) Port-au-Prince' },
  { value: 'America/Rio_Branco', name: '(GMT-05:00) Rio Branco' },
  { value: 'America/Toronto', name: '(GMT-05:00) Eastern Time - Toronto' },
  { value: 'Pacific/Easter', name: '(GMT-05:00) Easter Island' },
  { value: 'America/Caracas', name: '(GMT-04:30) Caracas' },
  { value: 'America/Asuncion', name: '(GMT-03:00) Asuncion' },
  { value: 'America/Barbados', name: '(GMT-04:00) Barbados' },
  { value: 'America/Boa_Vista', name: '(GMT-04:00) Boa Vista' },
  { value: 'America/Campo_Grande', name: '(GMT-03:00) Campo Grande' },
  { value: 'America/Cuiaba', name: '(GMT-03:00) Cuiaba' },
  { value: 'America/Curacao', name: '(GMT-04:00) Curacao' },
  { value: 'America/Grand_Turk', name: '(GMT-04:00) Grand Turk' },
  { value: 'America/Guyana', name: '(GMT-04:00) Guyana' },
  { value: 'America/Halifax', name: '(GMT-04:00) Atlantic Time - Halifax' },
  { value: 'America/La_Paz', name: '(GMT-04:00) La Paz' },
  { value: 'America/Manaus', name: '(GMT-04:00) Manaus' },
  { value: 'America/Martinique', name: '(GMT-04:00) Martinique' },
  { value: 'America/Port_of_Spain', name: '(GMT-04:00) Port of Spain' },
  { value: 'America/Porto_Velho', name: '(GMT-04:00) Porto Velho' },
  { value: 'America/Puerto_Rico', name: '(GMT-04:00) Puerto Rico' },
  { value: 'America/Santo_Domingo', name: '(GMT-04:00) Santo Domingo' },
  { value: 'America/Thule', name: '(GMT-04:00) Thule' },
  { value: 'Atlantic/Bermuda', name: '(GMT-04:00) Bermuda' },
  { value: 'America/St_Johns', name: '(GMT-03:30) Newfoundland Time - St. Johns' },
  { value: 'America/Araguaina', name: '(GMT-03:00) Araguaina' },
  { value: 'America/Argentina/Buenos_Aires', name: '(GMT-03:00) Buenos Aires' },
  { value: 'America/Bahia', name: '(GMT-03:00) Salvador' },
  { value: 'America/Belem', name: '(GMT-03:00) Belem' },
  { value: 'America/Cayenne', name: '(GMT-03:00) Cayenne' },
  { value: 'America/Fortaleza', name: '(GMT-03:00) Fortaleza' },
  { value: 'America/Godthab', name: '(GMT-03:00) Godthab' },
  { value: 'America/Maceio', name: '(GMT-03:00) Maceio' },
  { value: 'America/Miquelon', name: '(GMT-03:00) Miquelon' },
  { value: 'America/Montevideo', name: '(GMT-03:00) Montevideo' },
  { value: 'America/Paramaribo', name: '(GMT-03:00) Paramaribo' },
  { value: 'America/Recife', name: '(GMT-03:00) Recife' },
  { value: 'America/Santiago', name: '(GMT-03:00) Santiago' },
  { value: 'America/Sao_Paulo', name: '(GMT-02:00) Sao Paulo' },
  { value: 'Antarctica/Palmer', name: '(GMT-03:00) Palmer' },
  { value: 'Antarctica/Rothera', name: '(GMT-03:00) Rothera' },
  { value: 'Atlantic/Stanley', name: '(GMT-03:00) Stanley' },
  { value: 'America/Noronha', name: '(GMT-02:00) Noronha' },
  { value: 'Atlantic/South_Georgia', name: '(GMT-02:00) South Georgia' },
  { value: 'America/Scoresbysund', name: '(GMT-01:00) Scoresbysund' },
  { value: 'Atlantic/Azores', name: '(GMT-01:00) Azores' },
  { value: 'Atlantic/Cape_Verde', name: '(GMT-01:00) Cape Verde' },
  { value: 'Africa/Abidjan', name: '(GMT+00:00) Abidjan' },
  { value: 'Africa/Accra', name: '(GMT+00:00) Accra' },
  { value: 'Africa/Bissau', name: '(GMT+00:00) Bissau' },
  { value: 'Africa/Casablanca', name: '(GMT+00:00) Casablanca' },
  { value: 'Africa/El_Aaiun', name: '(GMT+00:00) El Aaiun' },
  { value: 'Africa/Monrovia', name: '(GMT+00:00) Monrovia' },
  { value: 'America/Danmarkshavn', name: '(GMT+00:00) Danmarkshavn' },
  { value: 'Atlantic/Canary', name: '(GMT+00:00) Canary Islands' },
  { value: 'Atlantic/Faroe', name: '(GMT+00:00) Faeroe' },
  { value: 'Atlantic/Reykjavik', name: '(GMT+00:00) Reykjavik' },
  { value: 'Etc/GMT', name: '(GMT+00:00) GMT (no daylight saving)' },
  { value: 'Europe/Dublin', name: '(GMT+00:00) Dublin' },
  { value: 'Europe/Lisbon', name: '(GMT+00:00) Lisbon' },
  { value: 'Europe/London', name: '(GMT+00:00) London' },
  { value: 'Africa/Algiers', name: '(GMT+01:00) Algiers' },
  { value: 'Africa/Ceuta', name: '(GMT+01:00) Ceuta' },
  { value: 'Africa/Lagos', name: '(GMT+01:00) Lagos' },
  { value: 'Africa/Ndjamena', name: '(GMT+01:00) Ndjamena' },
  { value: 'Africa/Tunis', name: '(GMT+01:00) Tunis' },
  { value: 'Africa/Windhoek', name: '(GMT+02:00) Windhoek' },
  { value: 'Europe/Amsterdam', name: '(GMT+01:00) Amsterdam' },
  { value: 'Europe/Andorra', name: '(GMT+01:00) Andorra' },
  { value: 'Europe/Belgrade', name: '(GMT+01:00) Central European Time - Belgrade' },
  { value: 'Europe/Berlin', name: '(GMT+01:00) Berlin' },
  { value: 'Europe/Brussels', name: '(GMT+01:00) Brussels' },
  { value: 'Europe/Budapest', name: '(GMT+01:00) Budapest' },
  { value: 'Europe/Copenhagen', name: '(GMT+01:00) Copenhagen' },
  { value: 'Europe/Gibraltar', name: '(GMT+01:00) Gibraltar' },
  { value: 'Europe/Luxembourg', name: '(GMT+01:00) Luxembourg' },
  { value: 'Europe/Madrid', name: '(GMT+01:00) Madrid' },
  { value: 'Europe/Malta', name: '(GMT+01:00) Malta' },
  { value: 'Europe/Monaco', name: '(GMT+01:00) Monaco' },
  { value: 'Europe/Oslo', name: '(GMT+01:00) Oslo' },
  { value: 'Europe/Paris', name: '(GMT+01:00) Paris' },
  { value: 'Europe/Prague', name: '(GMT+01:00) Central European Time - Prague' },
  { value: 'Europe/Rome', name: '(GMT+01:00) Rome' },
  { value: 'Europe/Stockholm', name: '(GMT+01:00) Stockholm' },
  { value: 'Europe/Tirane', name: '(GMT+01:00) Tirane' },
  { value: 'Europe/Vienna', name: '(GMT+01:00) Vienna' },
  { value: 'Europe/Warsaw', name: '(GMT+01:00) Warsaw' },
  { value: 'Europe/Zurich', name: '(GMT+01:00) Zurich' },
  { value: 'Africa/Cairo', name: '(GMT+02:00) Cairo' },
  { value: 'Africa/Johannesburg', name: '(GMT+02:00) Johannesburg' },
  { value: 'Africa/Maputo', name: '(GMT+02:00) Maputo' },
  { value: 'Africa/Tripoli', name: '(GMT+02:00) Tripoli' },
  { value: 'Asia/Amman', name: '(GMT+02:00) Amman' },
  { value: 'Asia/Beirut', name: '(GMT+02:00) Beirut' },
  { value: 'Asia/Damascus', name: '(GMT+02:00) Damascus' },
  { value: 'Asia/Gaza', name: '(GMT+02:00) Gaza' },
  { value: 'Asia/Jerusalem', name: '(GMT+02:00) Jerusalem' },
  { value: 'Asia/Nicosia', name: '(GMT+02:00) Nicosia' },
  { value: 'Europe/Athens', name: '(GMT+02:00) Athens' },
  { value: 'Europe/Bucharest', name: '(GMT+02:00) Bucharest' },
  { value: 'Europe/Chisinau', name: '(GMT+02:00) Chisinau' },
  { value: 'Europe/Helsinki', name: '(GMT+02:00) Helsinki' },
  { value: 'Europe/Istanbul', name: '(GMT+02:00) Istanbul' },
  { value: 'Europe/Kaliningrad', name: '(GMT+02:00) Moscow-01 - Kaliningrad' },
  { value: 'Europe/Kiev', name: '(GMT+02:00) Kiev' },
  { value: 'Europe/Riga', name: '(GMT+02:00) Riga' },
  { value: 'Europe/Sofia', name: '(GMT+02:00) Sofia' },
  { value: 'Europe/Tallinn', name: '(GMT+02:00) Tallinn' },
  { value: 'Europe/Vilnius', name: '(GMT+02:00) Vilnius' },
  { value: 'Africa/Khartoum', name: '(GMT+03:00) Khartoum' },
  { value: 'Africa/Nairobi', name: '(GMT+03:00) Nairobi' },
  { value: 'Antarctica/Syowa', name: '(GMT+03:00) Syowa' },
  { value: 'Asia/Baghdad', name: '(GMT+03:00) Baghdad' },
  { value: 'Asia/Qatar', name: '(GMT+03:00) Qatar' },
  { value: 'Asia/Riyadh', name: '(GMT+03:00) Riyadh' },
  { value: 'Europe/Minsk', name: '(GMT+03:00) Minsk' },
  { value: 'Europe/Moscow', name: '(GMT+03:00) Moscow+00 - Moscow' },
  { value: 'Asia/Tehran', name: '(GMT+03:30) Tehran' },
  { value: 'Asia/Baku', name: '(GMT+04:00) Baku' },
  { value: 'Asia/Dubai', name: '(GMT+04:00) Dubai' },
  { value: 'Asia/Tbilisi', name: '(GMT+04:00) Tbilisi' },
  { value: 'Asia/Yerevan', name: '(GMT+04:00) Yerevan' },
  { value: 'Europe/Samara', name: '(GMT+04:00) Moscow+01 - Samara' },
  { value: 'Indian/Mahe', name: '(GMT+04:00) Mahe' },
  { value: 'Indian/Mauritius', name: '(GMT+04:00) Mauritius' },
  { value: 'Indian/Reunion', name: '(GMT+04:00) Reunion' },
  { value: 'Asia/Kabul', name: '(GMT+04:30) Kabul' },
  { value: 'Antarctica/Mawson', name: '(GMT+05:00) Mawson' },
  { value: 'Asia/Aqtau', name: '(GMT+05:00) Aqtau' },
  { value: 'Asia/Aqtobe', name: '(GMT+05:00) Aqtobe' },
  { value: 'Asia/Ashgabat', name: '(GMT+05:00) Ashgabat' },
  { value: 'Asia/Dushanbe', name: '(GMT+05:00) Dushanbe' },
  { value: 'Asia/Karachi', name: '(GMT+05:00) Karachi' },
  { value: 'Asia/Tashkent', name: '(GMT+05:00) Tashkent' },
  { value: 'Asia/Yekaterinburg', name: '(GMT+05:00) Moscow+02 - Yekaterinburg' },
  { value: 'Indian/Kerguelen', name: '(GMT+05:00) Kerguelen' },
  { value: 'Indian/Maldives', name: '(GMT+05:00) Maldives' },
  { value: 'Asia/Calcutta', name: '(GMT+05:30) India Standard Time' },
  { value: 'Asia/Colombo', name: '(GMT+05:30) Colombo' },
  { value: 'Asia/Katmandu', name: '(GMT+05:45) Katmandu' },
  { value: 'Antarctica/Vostok', name: '(GMT+06:00) Vostok' },
  { value: 'Asia/Almaty', name: '(GMT+06:00) Almaty' },
  { value: 'Asia/Bishkek', name: '(GMT+06:00) Bishkek' },
  { value: 'Asia/Dhaka', name: '(GMT+06:00) Dhaka' },
  { value: 'Asia/Omsk', name: '(GMT+06:00) Moscow+03 - Omsk, Novosibirsk' },
  { value: 'Asia/Thimphu', name: '(GMT+06:00) Thimphu' },
  { value: 'Indian/Chagos', name: '(GMT+06:00) Chagos' },
  { value: 'Asia/Rangoon', name: '(GMT+06:30) Rangoon' },
  { value: 'Indian/Cocos', name: '(GMT+06:30) Cocos' },
  { value: 'Antarctica/Davis', name: '(GMT+07:00) Davis' },
  { value: 'Asia/Bangkok', name: '(GMT+07:00) Bangkok' },
  { value: 'Asia/Hovd', name: '(GMT+07:00) Hovd' },
  { value: 'Asia/Jakarta', name: '(GMT+07:00) Jakarta' },
  { value: 'Asia/Krasnoyarsk', name: '(GMT+07:00) Moscow+04 - Krasnoyarsk' },
  { value: 'Asia/Saigon', name: '(GMT+07:00) Hanoi' },
  { value: 'Asia/Ho_Chi_Minh', name: '(GMT+07:00) Ho Chi Minh' },
  { value: 'Indian/Christmas', name: '(GMT+07:00) Christmas' },
  { value: 'Antarctica/Casey', name: '(GMT+08:00) Casey' },
  { value: 'Asia/Brunei', name: '(GMT+08:00) Brunei' },
  { value: 'Asia/Choibalsan', name: '(GMT+08:00) Choibalsan' },
  { value: 'Asia/Hong_Kong', name: '(GMT+08:00) Hong Kong' },
  { value: 'Asia/Irkutsk', name: '(GMT+08:00) Moscow+05 - Irkutsk' },
  { value: 'Asia/Kuala_Lumpur', name: '(GMT+08:00) Kuala Lumpur' },
  { value: 'Asia/Macau', name: '(GMT+08:00) Macau' },
  { value: 'Asia/Makassar', name: '(GMT+08:00) Makassar' },
  { value: 'Asia/Manila', name: '(GMT+08:00) Manila' },
  { value: 'Asia/Shanghai', name: '(GMT+08:00) China Time - Beijing' },
  { value: 'Asia/Singapore', name: '(GMT+08:00) Singapore' },
  { value: 'Asia/Taipei', name: '(GMT+08:00) Taipei' },
  { value: 'Asia/Ulaanbaatar', name: '(GMT+08:00) Ulaanbaatar' },
  { value: 'Australia/Perth', name: '(GMT+08:00) Western Time - Perth' },
  { value: 'Asia/Pyongyang', name: '(GMT+08:30) Pyongyang' },
  { value: 'Asia/Dili', name: '(GMT+09:00) Dili' },
  { value: 'Asia/Jayapura', name: '(GMT+09:00) Jayapura' },
  { value: 'Asia/Seoul', name: '(GMT+09:00) Seoul' },
  { value: 'Asia/Tokyo', name: '(GMT+09:00) Tokyo' },
  { value: 'Asia/Yakutsk', name: '(GMT+09:00) Moscow+06 - Yakutsk' },
  { value: 'Pacific/Palau', name: '(GMT+09:00) Palau' },
  { value: 'Australia/Adelaide', name: '(GMT+10:30) Central Time - Adelaide' },
  { value: 'Australia/Darwin', name: '(GMT+09:30) Central Time - Darwin' },
  { value: 'Antarctica/DumontDUrville', name: "(GMT+10:00) Dumont D'Urville" },
  { value: 'Asia/Magadan', name: '(GMT+10:00) Moscow+07 - Magadan' },
  { value: 'Asia/Vladivostok', name: '(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk' },
  { value: 'Australia/Brisbane', name: '(GMT+10:00) Eastern Time - Brisbane' },
  { value: 'Australia/Hobart', name: '(GMT+11:00) Eastern Time - Hobart' },
  { value: 'Australia/Sydney', name: '(GMT+11:00) Eastern Time - Melbourne, Sydney' },
  { value: 'Pacific/Chuuk', name: '(GMT+10:00) Truk' },
  { value: 'Pacific/Guam', name: '(GMT+10:00) Guam' },
  { value: 'Pacific/Port_Moresby', name: '(GMT+10:00) Port Moresby' },
  { value: 'Pacific/Efate', name: '(GMT+11:00) Efate' },
  { value: 'Pacific/Guadalcanal', name: '(GMT+11:00) Guadalcanal' },
  { value: 'Pacific/Kosrae', name: '(GMT+11:00) Kosrae' },
  { value: 'Pacific/Norfolk', name: '(GMT+11:00) Norfolk' },
  { value: 'Pacific/Noumea', name: '(GMT+11:00) Noumea' },
  { value: 'Pacific/Pohnpei', name: '(GMT+11:00) Ponape' },
  { value: 'Asia/Kamchatka', name: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy' },
  { value: 'Pacific/Auckland', name: '(GMT+13:00) Auckland' },
  { value: 'Pacific/Fiji', name: '(GMT+13:00) Fiji' },
  { value: 'Pacific/Funafuti', name: '(GMT+12:00) Funafuti' },
  { value: 'Pacific/Kwajalein', name: '(GMT+12:00) Kwajalein' },
  { value: 'Pacific/Majuro', name: '(GMT+12:00) Majuro' },
  { value: 'Pacific/Nauru', name: '(GMT+12:00) Nauru' },
  { value: 'Pacific/Tarawa', name: '(GMT+12:00) Tarawa' },
  { value: 'Pacific/Wake', name: '(GMT+12:00) Wake' },
  { value: 'Pacific/Wallis', name: '(GMT+12:00) Wallis' },
  { value: 'Pacific/Apia', name: '(GMT+14:00) Apia' },
  { value: 'Pacific/Enderbury', name: '(GMT+13:00) Enderbury' },
  { value: 'Pacific/Fakaofo', name: '(GMT+13:00) Fakaofo' },
  { value: 'Pacific/Tongatapu', name: '(GMT+13:00) Tongatapu' },
  { value: 'Pacific/Kiritimati', name: '(GMT+14:00) Kiritimat' }
];

export const financingConfiguration = [
  { value: 1, name: '1 Year (9.25%)' },
  { value: 2, name: '2 Years (9.50%)' },
  { value: 3, name: '3 Years (9.75%)' }
];

export const OptionsHouse = [{ value: true, name: 'Apartment' }, { value: false, name: 'House' }];

/**
 * Transform text to title Case in each word
 * @param str {string}
 * @returns {string}
 */
export const titleCase = str => {
  const splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

/**
 * Remove keys from object and return new object
 * @param obj {Object}
 * @param keysDeleted {Array}
 * @returns {Object}
 */
export const outKeysFromObject = (obj, keysDeleted) => {
  let rest = Object.keys(obj)
    .filter(k => !keysDeleted.includes(k))
    .map(k => ({ [k]: obj[k] }));
  return Object.assign(...rest);
};
