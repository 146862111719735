import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, submit, reset } from 'redux-form';
import {
  fetchEnd,
  fetchStart,
  required,
  showNotification,
  Button,
  SaveButton,
  TabbedForm,
  FormTab,
  TextInput,
  ReferenceInput,
  SelectInput,
  FileInput,
  FileField,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  CREATE
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { FORM_NAME_LOAN_CREATE } from './create';
import loopbackRestClient from '../../../services/data-provider';
const { MILO_API_URL } = process.env;
const dataProvider = loopbackRestClient(MILO_API_URL);

class CompanyQuickCreateButton extends Component {
  state = {
    error: false,
    showDialog: false,
    loading: false
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    const { reset } = this.props;
    reset('company-quick-create-button');
    this.setState({ showDialog: false });
  };

  handleSaveClick = () => {
    const { submit } = this.props;

    // Trigger a submit of our custom quick create form
    // This is needed because our modal action buttons are oustide the form
    submit('company-quick-create-button');
  };

  handleSubmit = values => {
    const { change, fetchStart, fetchEnd, showNotification, reset } = this.props;

    this.setState({ loading: true });

    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    values.countryName = 'United States';
    values.clientId = values.userId;

    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider(CREATE, 'companies/create-company', { data: values })
      .then(({ data }) => {
        // Update the main react-admin form (in this case, the comments creation form)
        change(FORM_NAME_LOAN_CREATE, 'companyId', data.id);
      })
      .catch(error => {
        showNotification(error.message, 'error');
      })
      .finally(() => {
        reset('company-quick-create-button');
        this.setState({ showDialog: false, loading: false });
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  renderFormPartner = () => (
    <ArrayInput source="partner">
      <SimpleFormIterator>
        <TextInput source="name" validate={required()} />
        <TextInput source="address" validate={required()} />
        <TextInput source="phoneNumber" validate={required()} />
        <TextInput source="zipCode" validate={required()} />
        <TextInput source="cityName" validate={required()} />
        <TextInput source="stateName" validate={required()} />
        <TextInput source="countryName" validate={required()} />
        <NumberInput source="annualincome" validate={required()} />
        <FileInput source="passportimage" label="Passport" accept="application/pdf" validate={required()}>
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleFormIterator>
    </ArrayInput>
  );

  renderFormCompanyPartner = () => (
    <ArrayInput source="companyPartner">
      <SimpleFormIterator>
        <TextInput source="name" validate={required()} />
        <TextInput source="address" validate={required()} />
        <TextInput source="phoneNumber" validate={required()} />
        <TextInput source="zipCode" validate={required()} />
        <TextInput source="cityName" validate={required()} />
        <TextInput source="stateName" validate={required()} />
        <TextInput source="countryName" validate={required()} />
      </SimpleFormIterator>
    </ArrayInput>
  );

  render() {
    const { showDialog, isSubmitting } = this.state;
    const { values } = this.props;

    return (
      <Fragment>
        <Button onClick={this.handleClick} label="ra.action.create">
          <IconContentAdd />
        </Button>
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Create company">
          <DialogTitle>Create company</DialogTitle>
          <DialogContent>
            <TabbedForm
              form="company-quick-create-button"
              resource="companies"
              onSubmit={this.handleSubmit}
              toolbar={null}
              defaultValue={values}
            >
              <FormTab label="Company information">
                <TextInput source="name" validate={required()} />
                <TextInput source="address" validate={required()} />
                <TextInput source="phoneNumber" validate={required()} />
                <TextInput source="zipCode" validate={required()} />
                <TextInput source="cityName" validate={required()} />
                <TextInput source="stateName" validate={required()} />
                <ReferenceInput source="userId" reference="my-users" validate={required()}>
                  <SelectInput optionText="email" optionValue="id" disabled />
                </ReferenceInput>
              </FormTab>
              <FormTab label="Partner's information">
                {this.renderFormPartner()}
                {this.renderFormCompanyPartner()}
              </FormTab>
            </TabbedForm>
          </DialogContent>
          <DialogActions>
            <SaveButton saving={isSubmitting} onClick={this.handleSaveClick} />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  reset
};

CompanyQuickCreateButton.propTypes = {
  submit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  fetchStart: PropTypes.func.isRequired,
  fetchEnd: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default connect(
  null,
  mapDispatchToProps
)(CompanyQuickCreateButton);
