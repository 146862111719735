import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, submit, reset } from 'redux-form';
import {
  fetchEnd,
  fetchStart,
  required,
  showNotification,
  Button,
  SaveButton,
  TextInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  FileInput,
  FileField,
  BooleanInput,
  TabbedForm,
  FormTab,
  FormDataConsumer,
  CREATE,
  REDUX_FORM_NAME
} from 'react-admin';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/styles';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import loopbackRestClient from '../../../services/data-provider';
import { timeZones } from '../../../utils';
import { formatDate } from '../../../utils/functions';

const { MILO_API_URL } = process.env;
const dataProvider = loopbackRestClient(MILO_API_URL);

const styles = {
  hidden: { visibility: 'hidden', height: 0 }
};

class MyUserQuickCreateButton extends Component {
  state = {
    error: false,
    showDialog: false,
    isSubmitting: false
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleSaveClick = () => {
    const { submit } = this.props;

    // Trigger a submit of our custom quick create form
    // This is needed because our modal action buttons are oustide the form
    submit('my-user-quick-create-button');
  };

  handleSubmit = values => {
    const { change, fetchStart, fetchEnd, showNotification, reset } = this.props;
    this.setState({ isSubmitting: true });
    values.birthDate = formatDate(values.birthDate);

    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider(CREATE, 'my-users/manual-admin-create', { data: values })
      .then(({ data }) => {
        // Update the main react-admin form (in this case, the comments creation form)
        change(REDUX_FORM_NAME, 'userId', data.id);
        this.setState({ showDialog: false });
      })
      .catch(error => {
        showNotification(error.message, 'error');
      })
      .finally(() => {
        reset('my-user-quick-create-button');
        this.setState({ isSubmitting: false });
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  render() {
    const { showDialog, isSubmitting } = this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        <Button onClick={this.handleClick} label="ra.action.create">
          <IconContentAdd />
        </Button>
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Create borrower">
          <DialogTitle>Create borrower</DialogTitle>
          <DialogContent>
            <TabbedForm
              onSubmit={this.handleSubmit}
              submitOnEnter={false}
              toolbar={null}
              saving={isSubmitting}
              defaultValue={{ roleId: 3 }}
              form="my-user-quick-create-button"
            >
              <FormTab label="Client information">
                <TextInput source="firstName" validate={required()} />
                <TextInput source="lastName" validate={required()} />
                <ReferenceInput
                  source="roleId"
                  reference="my-roles"
                  validate={required()}
                  disabled
                  formClassName={classes.hidden}
                >
                  <SelectInput optionText="name" optionValue="id" />
                </ReferenceInput>
                <NumberInput source="annualIncome" validate={required()} />
                <DateInput source="birthDate" validate={required()} />
                <BooleanInput label="Has passport?" source="passportExist" />
                <FormDataConsumer>
                  {({ formData }) =>
                    formData.passportExist && (
                      <FileInput source="passportImage" label="Passport" accept="application/pdf">
                        <FileField source="src" title="title" />
                      </FileInput>
                    )
                  }
                </FormDataConsumer>
                <TextInput source="contactAddress" validate={required()} />
                <TextInput source="contactPhoneNumber" validate={required()} />
                <TextInput source="contactCountry" validate={required()} />
                <TextInput source="contactState" validate={required()} />
                <TextInput source="contactCity" validate={required()} />
                <TextInput source="contactZipCode" validate={required()} />
                <SelectInput
                  source="timeZone"
                  optionText="name"
                  optionValue="value"
                  choices={timeZones}
                  translateChoice={false}
                  validate={required()}
                />
                <TextInput source="email" type="email" validate={required()} />
              </FormTab>
            </TabbedForm>
          </DialogContent>
          <DialogActions>
            <SaveButton saving={isSubmitting} onClick={this.handleSaveClick} />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  reset
};

MyUserQuickCreateButton.propTypes = {
  reset: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  fetchStart: PropTypes.func.isRequired,
  fetchEnd: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  )
)(MyUserQuickCreateButton);
