import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  NumberField,
  DateField,
  Responsive,
  SimpleList,
  TextField,
  ReferenceField,
  translate,
  CardActions,
  CreateButton,
  RefreshButton,
  ShowButton,
  Filter,
  ReferenceInput,
  SelectInput
} from 'react-admin';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

const ListActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </CardActions>
);

const Filters = props => (
  <Filter {...props}>
    <ReferenceInput source="loanRequestStatusId" reference="loan-request-statuses" allowEmpty>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const Component = props => {
  const { translate } = props;

  return (
    <Card>
      <CardHeader title={translate(`resources.loan-requests.name`)} />
      <List {...props} bulkActionButtons={false} filters={<Filters />} sort={{ field: 'updatedAt', order: 'DESC' }}>
        <Responsive
          small={
            <SimpleList
              primaryText={record => record.uid}
              secondaryText={record => record.loanValue}
              tertiaryText={record => new Date(record.createdAt).toLocaleDateString()}
            />
          }
          medium={
            <Datagrid>
              <TextField source="uid" label="Loan ID" />
              <ReferenceField source="clientId" label="Name" reference="my-users">
                <TextField source="firstName" />
              </ReferenceField>
              <ReferenceField source="houseId" label="Loan Address" reference="houses">
                <TextField source="address" />
              </ReferenceField>
              <NumberField source="loanValue" label="Loan Amount" />
              <ReferenceField source="loanRequestStatusId" label="Request status" reference="loan-request-statuses">
                <TextField source="name" />
              </ReferenceField>
              <DateField source="updatedAt" label="Update Date" showTime />
              <ShowButton label="" />
            </Datagrid>
          }
        />
      </List>
    </Card>
  );
};

ListActions.propTypes = {
  basePath: PropTypes.string
};

export default translate(Component);
