import React from 'react';
import { Create, SimpleForm, TextInput, required, email } from 'react-admin';
import ActionList, { redirect } from '../../utils/ActionList';

const validateEmail = email();

const Componet = props => (
  <Create {...props} actions={<ActionList />}>
    <SimpleForm submitOnEnter={false} redirect={redirect}>
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="companyName" />
      <TextInput source="email" type="email" validate={validateEmail} />
      <TextInput source="phoneNumber" validate={required()} />
    </SimpleForm>
  </Create>
);

export default Componet;
