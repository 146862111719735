import React from 'react';
import {
  List,
  Datagrid,
  NumberField,
  DateField,
  Filter,
  TextInput,
  Responsive,
  SimpleList,
  TextField,
  ReferenceField,
  ShowButton
} from 'react-admin';

const { MILO_ADMIN_ROLE_CODE } = process.env;

const Filters = props => (
  <Filter {...props}>
    <TextInput source="name" />
    <TextInput source="code" />
  </Filter>
);

export const Component = ({ permissions, ...props }) => (
  <List {...props} filters={<Filters />} bulkActionButtons={false}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.address}
          secondaryText={record => record.neighborhood}
          tertiaryText={record => new Date(record.createdAt).toLocaleDateString()}
        />
      }
      medium={
        <Datagrid>
          <NumberField source="id" />
          <TextField source="address" />
          <ReferenceField source="userId" reference="my-users">
            <TextField source="email" />
          </ReferenceField>

          <ReferenceField source="propertyTypeId" reference="property-types">
            <TextField source="name" />
          </ReferenceField>

          {permissions === MILO_ADMIN_ROLE_CODE ? (
            <ReferenceField source="cityId" reference="cities">
              <TextField source="name" />
            </ReferenceField>
          ) : (
            <TextField source="cityName" />
          )}
          {permissions !== MILO_ADMIN_ROLE_CODE && <TextField source="stateName" />}
          <TextField source="zipCode" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
          <ShowButton label="" />
        </Datagrid>
      }
    />
  </List>
);

export default Component;
