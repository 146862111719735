import { List as ListIcon } from '@material-ui/icons';

import List from './list';
import Create from './create/create';
import Show from './show/show';

/**
 * The codes for icons is the codes from loan request status in the database
 */
export default {
  List,
  icon: ListIcon,
  Create,
  Show
};
