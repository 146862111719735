import React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import ActionList, { redirect } from '../../utils/ActionList';

const Componet = props => (
  <Create {...props} actions={<ActionList />}>
    <SimpleForm submitOnEnter={false} redirect={redirect}>
      <TextInput source="code" validate={required()} />
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Create>
);

export default Componet;
