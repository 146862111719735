import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit, reset } from 'redux-form';
import {
  Button,
  SaveButton,
  TabbedForm,
  FormTab,
  TextInput,
  SelectInput,
  ReferenceInput,
  required,
  fetchEnd,
  fetchStart,
  UPDATE,
  showNotification,
  refreshView
} from 'react-admin';

import IconCancel from '@material-ui/icons/Cancel';
import IconContentEdit from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import loopbackRestClient from '../../../services/data-provider';

const { MILO_API_URL } = process.env;
const dataProvider = loopbackRestClient(MILO_API_URL);

class MyLoanEdit extends React.Component {
  state = {
    showDialog: false,
    isSubmitting: false
  };

  handleSaveClick = () => {
    const { submit } = this.props;

    // Trigger a submit of our custom quick create form
    // This is needed because our modal action buttons are oustide the form
    submit('my-loan-quick-edit-button');
  };

  handleSubmit = values => {
    const { fetchStart, fetchEnd, showNotification, reset, refreshView } = this.props;

    this.setState({ isSubmitting: true });
    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider(UPDATE, 'my-users/update-manual-loan', {
      data: {
        loanId: values.id,
        houseId: values.houseId,
        loanPurposeId: values.loanPurposeId,
        financingConfigurationId: values.financingConfigurationId,
        loanValue: values.loanValue,
        loanRequestStatusId: values.loanRequestStatusId,
        loanOfficerId: values.loanOfficerId
      }
    })
      .then(({ data }) => {
        this.setState({ showDialog: false });
        refreshView();
      })
      .catch(error => {
        showNotification(error.message, 'error');
      })
      .finally(() => {
        this.setState({ isSubmitting: false });
        reset('my-loan-quick-edit-button');
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  render() {
    const { showDialog, isSubmitting } = this.state;
    const { values } = this.props;
    let defaultValue = {};

    if (values) {
      defaultValue = {
        ...values,
        loanId: values.id
      };
    }

    return (
      <>
        <Button onClick={() => this.setState({ showDialog: true })} label="Edit loan">
          <IconContentEdit />
        </Button>
        <Dialog fullWidth open={showDialog} onClose={() => this.setState({ showDialog: false })} aria-label="Edit loan">
          <DialogTitle>Edit loan</DialogTitle>
          <DialogContent>
            <TabbedForm
              onSubmit={this.handleSubmit}
              submitOnEnter={false}
              saving={isSubmitting}
              defaultValue={defaultValue}
              toolbar={null}
              form="my-loan-quick-edit-button"
            >
              <FormTab label="Loan information">
                <TextInput source="loanValue" validate={required()} />
                <ReferenceInput source="loanPurposeId" reference="loan-purposes" validate={required()}>
                  <SelectInput optionText="name" optionValue="id" />
                </ReferenceInput>
                <ReferenceInput
                  source="financingConfigurationId"
                  reference="financing-configurations"
                  allowEmpty
                  validate={required()}
                  sort={{ order: 'ASC' }}
                >
                  <SelectInput
                    optionText={choice => `${choice.units} ${choice.timeUnit} (${choice.percentage * 100}%)`}
                    optionValue="id"
                  />
                </ReferenceInput>
              </FormTab>
            </TabbedForm>
          </DialogContent>
          <DialogActions>
            <SaveButton saving={isSubmitting} onClick={this.handleSaveClick} />
            <Button label="ra.action.cancel" onClick={() => this.setState({ showDialog: false })}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  reset,
  refreshView
};

MyLoanEdit.propTypes = {
  submit: PropTypes.func.isRequired,
  fetchStart: PropTypes.func.isRequired,
  fetchEnd: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  refreshView: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default connect(
  null,
  mapDispatchToProps
)(MyLoanEdit);
