import React from 'react';
import { Edit, SimpleForm, DisabledInput, TextInput } from 'react-admin';
import ActionList, { redirect } from '../../utils/ActionList';
import EditToolbar from '../../utils/EditToolbar';

const Component = props => (
  <Edit {...props} actions={<ActionList />}>
    <SimpleForm submitOnEnter={false} redirect={redirect} toolbar={<EditToolbar />}>
      <DisabledInput source="id" label="ID" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="companyName" />
      <TextInput source="email" type="email" />
      <TextInput source="phoneNumber" />
    </SimpleForm>
  </Edit>
);

export default Component;
