import React from 'react';
import {
  Edit,
  required,
  TabbedForm,
  FormTab,
  DisabledInput,
  NumberField,
  DateField,
  SelectField,
  TextField,
  BooleanInput,
  SelectInput,
  ImageInput,
  ImageField,
  SimpleForm,
  FileInput,
  FileField
} from 'react-admin';
import ActionList, { redirect } from '../../utils/ActionList';
import EditToolbar from '../../utils/EditToolbar';
import ViewTitle from '../../utils/ViewTitle';
import { withStyles } from '@material-ui/styles';
import { loanPurpose, propertyType, timeZones, financingConfiguration } from '../../utils/';
const { MILO_ROLE_CODE } = process.env;
const styles = {
  inlineBlock: { display: 'inline-flex', margin: '0px 1rem 1rem 2rem', width: '350px' }
};

const Component = ({ permissions, classes, ...props }) => (
  <Edit {...props} title={<ViewTitle resource={props.resource} field="email" />} actions={<ActionList />}>
    {permissions === MILO_ROLE_CODE ? (
      <SimpleForm submitOnEnter={false} redirect={redirect} toolbar={<EditToolbar />}>
        <DisabledInput source="id" label="ID" />
        <TextField source="email" type="email" />
        <TextField source="firstName" />
        <TextField source="middleName" />
        <TextField source="lastName" />
        <BooleanInput source="active" />
      </SimpleForm>
    ) : (
      <TabbedForm submitOnEnter={false} redirect={false} toolbar={<EditToolbar />}>
        <FormTab label="User">
          <TextField source="client_information" className="titleSection" />
          <DisabledInput source="id" label="ID" formClassName={classes.inlineBlock} />
          <TextField source="firstName" formClassName={classes.inlineBlock} />
          <TextField source="lastName" formClassName={classes.inlineBlock} />
          <NumberField source="clientInformation.annualIncome" formClassName={classes.inlineBlock} />
          <TextField source="email" type="email" formClassName={classes.inlineBlock} />
          <DateField source="clientInformation.birthDate" locales="en-US" formClassName={classes.inlineBlock} />
          <SelectInput
            source="timeZone"
            optionText="name"
            optionValue="value"
            choices={timeZones}
            translateChoice={false}
            formClassName={classes.inlineBlock}
            validate={required()}
          />
          <BooleanInput source="active" formClassName={classes.inlineBlock} />
          <TextField source="loan_address_information" className="titleSection" />
          <TextField source="houses[0].stateName" formClassName={classes.inlineBlock} />
          <TextField source="houses[0].cityName" formClassName={classes.inlineBlock} />
          <TextField source="houses[0].address" formClassName={classes.inlineBlock} />
          <TextField source="houses[0].zipCode" formClassName={classes.inlineBlock} />
          <SelectField
            source="houses[0].propertyTypeId"
            optionText="name"
            optionValue="id"
            choices={propertyType}
            formClassName={classes.inlineBlock}
          />
          <SelectField
            source="clientLoanRequests[0].loanPurposeId"
            optionText="name"
            optionValue="id"
            choices={loanPurpose}
            formClassName={classes.inlineBlock}
          />
          <TextField source="client_contact_information" className="titleSection" />
          <TextField source="residenceAddress.address" label="Address" formClassName={classes.inlineBlock} />
          <TextField source="residenceAddress.zipCode" label="Zip code" formClassName={classes.inlineBlock} />
          <TextField source="residenceAddress.phoneNumber" label="Phone number" formClassName={classes.inlineBlock} />
          <TextField source="loan_information" className="titleSection" />
          <NumberField source="clientLoanRequests[0].loanValue" formClassName={classes.inlineBlock} />
          <SelectField
            source="clientLoanRequests[0].financingConfigurationId"
            optionText="name"
            optionValue="value"
            choices={financingConfiguration}
            translateChoice={false}
            defaultValue={1}
            formClassName={classes.inlineBlock}
          />
        </FormTab>
        <FormTab label="Documents">
          <ImageInput source="passportImage" accept="image/*" placeholder={<p>Drop your image here</p>}>
            <ImageField source="src" title="title" />
          </ImageInput>
          <ImageField source="passport.passportImageUrl" title="Passport Image" />
          <ImageInput source="visaImage" accept="image/*" placeholder={<p>Drop your image here</p>}>
            <ImageField source="src" title="title" />
          </ImageInput>
          <ImageField source="passport.visaImageUrl" title="Visa Image" />
          <FileInput source="feeEstimate" accept="application/pdf" placeholder={<p>Drop your file here</p>}>
            <FileField source="src" title="title" />
          </FileInput>
          <FileField
            source="clientLoanRequests[0].generatedDocuments[0].url"
            title="Document Fee Estimate"
            target="_blank"
          />
        </FormTab>
      </TabbedForm>
    )}
  </Edit>
);

export default withStyles(styles)(Component);
