import React from 'react';
import { Edit, SimpleForm, DisabledInput, TextInput, required } from 'react-admin';
import ActionList, { redirect } from '../../utils/ActionList';
import EditToolbar from '../../utils/EditToolbar';

const Component = props => (
  <Edit {...props} actions={<ActionList />}>
    <SimpleForm submitOnEnter={false} redirect={redirect} toolbar={<EditToolbar />}>
      <DisabledInput source="id" label="ID" />
      <TextInput source="code" validate={required()} />
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Edit>
);

export default Component;
