import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardHeader, CardActions } from '@material-ui/core';
import { changeLocale as changeLocaleAction, Title, translate } from 'react-admin';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

class Configuration extends Component {
  switchToEnglish = () => this.props.changeLocale('en');
  switchToSpanish = () => this.props.changeLocale('es');

  render() {
    const { locale, translate } = this.props;
    return (
      <Card>
        <Title title={translate('customRoutes.configurations.name')} />
        <CardHeader
          title={translate('customRoutes.configurations.language.title')}
          subheader={translate('customRoutes.configurations.language.subtitle')}
        />
        <CardActions>
          <Button
            style={{ margin: '1em' }}
            onClick={this.switchToEnglish}
            color={locale === 'en' ? 'primary' : 'secondary'}
            variant="contained"
          >
            en
          </Button>
          <Button
            style={{ margin: '1em' }}
            onClick={this.switchToSpanish}
            color={locale === 'es' ? 'primary' : 'secondary'}
            variant="contained"
          >
            es
          </Button>
        </CardActions>
      </Card>
    );
  }
}

Configuration.propTypes = {
  changeLocale: PropTypes.func,
  locale: PropTypes.string,
  translate: PropTypes.func
};

const mapStateToProps = state => ({
  locale: state.i18n.locale
});

const enhance = compose(
  translate,
  connect(
    mapStateToProps,
    { changeLocale: changeLocaleAction }
  )
);

export default enhance(Configuration);
