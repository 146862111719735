import React from 'react';
import {
  List,
  Datagrid,
  NumberField,
  DateField,
  EmailField,
  BooleanField,
  TextField,
  Responsive,
  SimpleList,
  ReferenceField,
  NumberInput,
  BooleanInput,
  Filter,
  Toolbar,
  SaveButton,
  Edit,
  SimpleForm
} from 'react-admin';

const rowStyle = record => ({
  backgroundColor: record.entries >= 3 ? '#ffaeaf' : 'white'
});

const Filters = props => (
  <Filter {...props}>
    <NumberInput source="step" />
    <NumberInput source="entries" />
  </Filter>
);

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const PrequalEdit = props => (
  <Edit {...props} title=" ">
    <SimpleForm form={`post_prequal_${props.id}`} toolbar={<CustomToolbar />}>
      <BooleanInput source="processed" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <ReferenceField source="propertyTypeId" reference="property-types">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="loanPurposeId" reference="loan-purposes">
        <TextField source="name" />
      </ReferenceField>
    </SimpleForm>
  </Edit>
);

export const Component = props => (
  <List {...props} filters={<Filters />} bulkActionButtons={false}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.email}
          secondaryText={record => new Date(record.createdAt).toLocaleDateString()}
          tertiaryText={record => new Date(record.updatedAt).toLocaleDateString()}
        />
      }
      medium={
        <Datagrid expand={<PrequalEdit />} rowStyle={rowStyle}>
          <NumberField source="id" />
          <EmailField source="email" />
          <TextField source="address" />
          <TextField source="annualIncome" />
          <NumberField source="step" />
          <NumberField source="entries" />
          <BooleanField source="processed" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </Datagrid>
      }
    />
  </List>
);

export default Component;
