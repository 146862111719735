import React from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  SimpleForm,
  NumberInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  required,
  REDUX_FORM_NAME,
  CREATE,
  fetchEnd,
  fetchStart,
  showNotification,
  SaveButton
} from 'react-admin';
import { connect } from 'react-redux';
import { change, submit, reset } from 'redux-form';
import { Redirect } from 'react-router';

import MyUserQuickCreateButton from './MyUserQuickCreateButton';
import HousesQuickCreateButton from './HousesQuickCreateButton';
import CompanyQuickCreateButton from './CompanyQuickCreateButton';
import ActionList from '../../../utils/ActionList';
import { outKeysFromObject } from '../../../utils';
import loopbackRestClient from '../../../services/data-provider';

const { MILO_API_URL } = process.env;
const dataProvider = loopbackRestClient(MILO_API_URL);
export const FORM_NAME_LOAN_CREATE = 'loan-create';

class Component extends React.Component {
  state = {
    redirect: false,
    loanListRedirect: 0,
    isSubmitting: false
  };

  componentDidMount() {
    const { reset } = this.props;
    reset(FORM_NAME_LOAN_CREATE);
  }

  handleSaveClick = () => {
    const { submit } = this.props;

    // Trigger a submit of our custom quick create form
    // This is needed because our modal action buttons are oustide the form
    submit('loan-create');
  };

  handleSubmit = values => {
    const { fetchStart, fetchEnd, showNotification, reset } = this.props;

    this.setState({ isSubmitting: true });
    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    dataProvider(CREATE, 'my-users/manual-loan-create', { data: { ...values, loanRequestStatusId: 5 } })
      .then(({ data }) => {
        this.setState({ redirect: true, loanListRedirect: data.loanRequestStatusId, isSubmitting: false });
        reset('FORM_NAME_LOAN_CREATE');
      })
      .catch(error => {
        this.setState({ redirect: false, loanListRedirect: 0, isSubmitting: false });
        showNotification(error.message, 'error');
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  render() {
    const { redirect, loanListRedirect, isSubmitting } = this.state;
    const rest = outKeysFromObject(this.props, [
      'fetchStart',
      'fetchEnd',
      'showNotification',
      'change',
      'submit',
      'reset'
    ]);

    if (redirect && loanListRedirect !== 0) {
      return <Redirect to={`/loan-requests?filter=%7B"loanRequestStatusId"%3A"${loanListRedirect}"%7D`} />;
    }

    return (
      <Create {...rest} actions={<ActionList />}>
        <SimpleForm
          form={FORM_NAME_LOAN_CREATE}
          onSubmit={this.handleSubmit}
          submitOnEnter={false}
          saving={isSubmitting}
          toolbar={null}
        >
          <SelectInput
            source="propertyType"
            optionText="name"
            optionValue="code"
            label="Ownership of property"
            choices={[{ name: 'Company', code: 'C01' }, { name: 'Individual', code: 'I01' }]}
            translateChoice={false}
            validate={required()}
          />
          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => (
              <>
                <div>
                  <ReferenceInput
                    source="userId"
                    reference="assigned-roles"
                    label="Borrower"
                    validate={required()}
                    sort={{ order: 'ASC' }}
                    filter={{ roleId: 3 }}
                    onChange={() => dispatch(change(REDUX_FORM_NAME, 'houseId', ''))}
                    {...rest}
                  >
                    <SelectInput optionText="myUser.email" optionValue="userId" />
                  </ReferenceInput>
                  <MyUserQuickCreateButton />
                </div>
                {formData.propertyType === 'C01' && formData.userId && (
                  <div>
                    <ReferenceInput
                      source="companyId"
                      reference="companies"
                      label="Company"
                      validate={required()}
                      filter={{ userId: formData.userId }}
                      {...rest}
                    >
                      <SelectInput optionText="name" optionValue="id" allowEmpty {...rest} />
                    </ReferenceInput>
                    <CompanyQuickCreateButton values={{ userId: formData.userId }} />
                  </div>
                )}
                {formData.userId && (
                  <div>
                    <ReferenceInput
                      source="houseId"
                      reference="houses"
                      label="Property address"
                      validate={required()}
                      filter={{ userId: formData.userId }}
                      {...rest}
                    >
                      <SelectInput optionText="address" optionValue="id" {...rest} />
                    </ReferenceInput>
                    <HousesQuickCreateButton defaultValue={{ userId: formData.userId }} />
                  </div>
                )}
              </>
            )}
          </FormDataConsumer>
          <NumberInput source="loanValue" validate={required()} />
          <ReferenceInput source="loanPurposeId" reference="loan-purposes" allowEmpty validate={required()}>
            <SelectInput optionText="name" optionValue="id" />
          </ReferenceInput>
          <ReferenceInput source="loanOfficerId" reference="assigned-roles" allowEmpty filter={{ roleId: 2 }}>
            <SelectInput optionText="myUser.email" optionValue="userId" />
          </ReferenceInput>
          <ReferenceInput
            source="financingConfigurationId"
            reference="financing-configurations"
            validate={required()}
            sort={{ order: 'ASC' }}
          >
            <SelectInput
              optionText={choice => `${choice.units} ${choice.timeUnit} (${choice.percentage * 100}%)`}
              optionValue="id"
            />
          </ReferenceInput>
          <SaveButton saving={isSubmitting} onClick={this.handleSaveClick} />
        </SimpleForm>
      </Create>
    );
  }
}

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  reset
};

Component.propTypes = {
  submit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  fetchStart: PropTypes.func.isRequired,
  fetchEnd: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  defaultValue: PropTypes.object
};

export default connect(
  null,
  mapDispatchToProps
)(Component);
