export const dashboardStyles = {
  welcome: {
    marginBottom: '2em'
  },
  flex: { display: 'flex' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em', marginTop: '1em' },
  singleCol: { marginTop: '2em' }
};

export const welcomeStyles = {
  actions: {
    float: 'right'
  },
  buttons: {
    alignItems: 'center'
  }
};
