import storage from './storage';

/**
 * function to know if the user is logged in
 *
 * @returns {boolean} indicate if the user is logged or not
 */
export const isLoggedIn = () => {
  const token = storage.load('token');
  if (token) {
    return true;
  }
  return false;
};

/**
 * Format date from select input date from react admin
 * @param date [AAAA-MM-DDT00:00:00.000Z]
 * @returns {string|*} (DD-MM-AAAA)
 */
export const formatDate = date => {
  let formatDate = date.split('-');
  let days = '';

  if (formatDate.length === 3) {
    if (formatDate[2].length !== 2) {
      days = formatDate[2].split('T')[0];
    } else {
      days = formatDate[2];
    }

    return `${formatDate[1]}/${days}/${formatDate[0]}`;
  }

  return date;
};
