import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppBar, GET_LIST } from 'react-admin';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/styles';
import compose from 'recompose/compose';

import { dashboardStyles } from './styles';

import Welcome from './Welcome';
import NewUsers from './NewUsers';
import loopbackRestClient from '../services/data-provider';
import { isLoggedIn } from '../utils/functions';

const apiUrl = process.env.MILO_API_URL;

const dataProvider = loopbackRestClient(apiUrl);

class Dashboard extends Component {
  state = {
    newUsers: [],
    nbNewUsers: 0
  };

  componentDidMount() {
    if (isLoggedIn()) {
      dataProvider(GET_LIST, 'my-users', {
        filter: {
          limit: 10
        },
        sort: {
          field: 'id',
          order: 'DESC'
        },
        pagination: { page: 1, perPage: 100 }
      })
        .then(respose => respose.data)
        .then(newUsers => {
          this.setState({ newUsers });
          this.setState({ nbNewUsers: newUsers.reduce(nb => ++nb, 0) });
        });
    }
  }

  render() {
    const { nbNewUsers, newUsers } = this.state;
    const { width, classes } = this.props;

    return (
      <div>
        {width === 'xs' && <AppBar title="Posters Galore Admin" />}
        <Welcome className={classes.welcome} />
        <div className={classes.flex}>
          <div className={classes.leftCol}>
            <div className={classes.flex} />
            <div className={classes.singleCol} />
          </div>
          <div className={classes.rightCol}>
            <div className={classes.flex}>
              <NewUsers nb={nbNewUsers} visitors={newUsers} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  width: PropTypes.string,
  classes: PropTypes.object,
  permissions: PropTypes.string
};

const enhance = compose(
  withWidth(),
  withStyles(dashboardStyles)
);

export default enhance(Dashboard);
