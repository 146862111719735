import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';

import SvgIcon from '@material-ui/core/SvgIcon';
import { fade, withStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';

const foo = [
  {
    name: 'Milo Credit',
    address: 'Miami',
    phoneNumber: '+573216549874',
    cityName: 'Miami',
    stateName: 'Florida',
    countryName: 'United States',
    zipCode: '33031',
    clientId: 1,
    partner: [
      {
        name: 'Josip',
        address: 'Alla tambien',
        cityName: 'Miami',
        stateName: 'Florida',
        countryName: 'United States',
        zipCode: '33031',
        phoneNumber: '1234567896',
        annualincome: 300000,
        passportimage: 'BASE64'
      },
      {
        name: 'Josip 2',
        address: 'Alla tambien',
        cityName: 'Miami',
        stateName: 'Florida',
        countryName: 'United States',
        zipCode: '33031',
        phoneNumber: '1234567896',
        annualincome: 300000,
        passportimage: 'BASE64'
      },
      {
        name: 'Josip 3',
        address: 'Alla tambien',
        cityName: 'Miami',
        stateName: 'Florida',
        countryName: 'United States',
        zipCode: '33031',
        phoneNumber: '1234567896',
        annualincome: 300000,
        passportimage: 'BASE64'
      }
    ],
    companyPartner: [
      {
        name: 'Milo Tech Colombia',
        address: 'Av 8N # 25 - 26',
        phoneNumber: '3164420180',
        cityName: 'Medellin',
        stateName: 'Antioquia',
        countryName: 'Colombia',
        zipCode: '760001',
        partner: [
          {
            name: 'William Penagos',
            address: 'Av 8N # 25 - 26',
            phoneNumber: '3164420180',
            cityName: 'Medellin',
            stateName: 'Antioquia',
            countryName: 'Colombia',
            zipCode: '760001',
            annualincome: 28000,
            passportimage: 'OTRABASE64'
          },
          {
            name: 'William Penagos 2',
            address: 'Av 8N # 25 - 26',
            phoneNumber: '3164420180',
            cityName: 'Medellin',
            stateName: 'Antioquia',
            countryName: 'Colombia',
            zipCode: '760001',
            annualincome: 28000,
            passportimage: 'OTRABASE64'
          }
        ]
      },
      {
        name: 'Milo Tech Jamundi',
        address: 'Av 8N # 25 - 26',
        phoneNumber: '3164420180',
        cityName: 'Medellin',
        stateName: 'Antioquia',
        countryName: 'Colombia',
        zipCode: '760001',
        partner: [
          {
            name: 'Richard Soon',
            address: 'Av 8N # 25 - 26',
            phoneNumber: '3164420180',
            cityName: 'Medellin',
            stateName: 'Antioquia',
            countryName: 'Colombia',
            zipCode: '760001',
            annualincome: 28000,
            passportimage: 'OTRABASE64'
          },
          {
            name: 'Richard Soon 2',
            address: 'Av 8N # 25 - 26',
            phoneNumber: '3164420180',
            cityName: 'Medellin',
            stateName: 'Antioquia',
            countryName: 'Colombia',
            zipCode: '760001',
            annualincome: 28000,
            passportimage: 'OTRABASE64'
          }
        ],
        companyPartner: [
          {
            name: 'Milo Tech Pance',
            address: 'Av 8N # 25 - 26',
            phoneNumber: '3164420180',
            cityName: 'Medellin',
            stateName: 'Antioquia',
            countryName: 'Colombia',
            zipCode: '760001',
            partner: [
              {
                name: 'David 1',
                address: 'Av 8N # 25 - 26',
                phoneNumber: '3164420180',
                cityName: 'Medellin',
                stateName: 'Antioquia',
                countryName: 'Colombia',
                zipCode: '760001',
                annualincome: 28000,
                passportimage: 'OTRABASE64'
              },
              {
                name: 'David 2',
                address: 'Av 8N # 25 - 26',
                phoneNumber: '3164420180',
                cityName: 'Medellin',
                stateName: 'Antioquia',
                countryName: 'Colombia',
                zipCode: '760001',
                annualincome: 28000,
                passportimage: 'OTRABASE64'
              }
            ]
          },
          {
            name: 'Milo Tech Jamundi',
            address: 'Av 8N # 25 - 26',
            phoneNumber: '3164420180',
            cityName: 'Medellin',
            stateName: 'Antioquia',
            countryName: 'Colombia',
            zipCode: '760001',
            partner: [
              {
                name: 'Richard Soon',
                address: 'Av 8N # 25 - 26',
                phoneNumber: '3164420180',
                cityName: 'Medellin',
                stateName: 'Antioquia',
                countryName: 'Colombia',
                zipCode: '760001',
                annualincome: 28000,
                passportimage: 'OTRABASE64'
              },
              {
                name: 'Richard Soon 2',
                address: 'Av 8N # 25 - 26',
                phoneNumber: '3164420180',
                cityName: 'Medellin',
                stateName: 'Antioquia',
                countryName: 'Colombia',
                zipCode: '760001',
                annualincome: 28000,
                passportimage: 'OTRABASE64'
              }
            ]
          }
        ]
      }
    ]
  }
];

const styles = {
  moreInfo: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#2196f3'
  }
};

const MinusSquare = props => {
  return (
    <SvgIcon fontSize="inherit" {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
};

const PlusSquare = props => {
  return (
    <SvgIcon fontSize="inherit" {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
};

const CloseSquare = props => {
  return (
    <SvgIcon className="close" fontSize="inherit" {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
};

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` }
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  in: PropTypes.bool
};

// eslint-disable-next-line no-unused-vars
const StyledTreeItem = withStyles(theme => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3
    }
  },
  group: {
    marginLeft: 12,
    paddingLeft: 12,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`
  }
}))(props => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

class Tree extends React.Component {
  renderItem = (item, countNode = 0) =>
    item.map(obj => {
      const info = (typeInfo = 'company') => (
        <div>
          <div>{`Address: ${obj.address}`}</div>
          <div>{`Phone number: ${obj.phoneNumber}`}</div>
          <div>{`City: ${obj.cityName}`}</div>
          <div>{`State: ${obj.stateName}`}</div>
          <div>{`Country: ${obj.countryName}`}</div>
          <div>{`Zip Code: ${obj.zipCode}`}</div>
          {typeInfo === 'partner' && (
            <div className={this.props.classes.moreInfo} onClick={() => alert('Holii')}>
              More Info
            </div>
          )}
        </div>
      );
      if (!obj.hasOwnProperty('partner') && !obj.hasOwnProperty('companyPartner')) {
        return (
          <StyledTreeItem nodeId={`${countNode + Math.random()}`} key={countNode + Math.random()} label={obj.name}>
            {info('partner')}
          </StyledTreeItem>
        );
      } else if (obj.hasOwnProperty('partner') && !obj.hasOwnProperty('companyPartner')) {
        return (
          <StyledTreeItem nodeId={`${countNode + Math.random()}`} key={countNode + Math.random()} label={obj.name}>
            {info('company')}
            <StyledTreeItem nodeId={`${countNode + Math.random()}`} label="Partners">
              {obj.hasOwnProperty('partner') && obj.partner.length > 0
                ? this.renderItem(obj.partner, countNode + Math.random())
                : null}
            </StyledTreeItem>
          </StyledTreeItem>
        );
      }

      return (
        <StyledTreeItem nodeId={`${countNode + Math.random()}`} key={countNode + Math.random()} label={obj.name}>
          {info('company')}
          <StyledTreeItem nodeId={`${countNode + Math.random()}`} label="Partners">
            {obj.hasOwnProperty('partner') && obj.partner.length > 0
              ? this.renderItem(obj.partner, countNode + Math.random())
              : null}
          </StyledTreeItem>
          <StyledTreeItem nodeId={`${countNode + Math.random()}`} label="Companies">
            {obj.hasOwnProperty('companyPartner') && obj.companyPartner.length > 0
              ? this.renderItem(obj.companyPartner, countNode + Math.random())
              : null}
          </StyledTreeItem>
        </StyledTreeItem>
      );
    });

  render() {
    return (
      <TreeView
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}
      >
        {this.renderItem(foo)}
      </TreeView>
    );
  }
}

Tree.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Tree);
