import spanishMessages from 'ra-language-spanish';

export default {
  ...spanishMessages,
  dashboard: {
    title: 'Administrador Milo Credit',
    welcome: {
      title: 'Bienvenido!',
      subtitle: 'Este es el administrador de Milo Credit, por favor se cuidadoso con lo que haces aquí!',
      toWebButton: 'Sitio web Milo Credit'
    },
    newUsers: 'Nuevos usarios'
  },
  customRoutes: {
    myAccount: 'Mi cuenta',
    configurations: {
      menuName: 'Configuraciones',
      name: 'Configuraciones',
      language: {
        title: 'Lenguaje',
        subtitle: 'Selecciona el lenguaje que tu quieras.'
      }
    }
  },
  auth: {
    email: 'Dirección email'
  },
  resources: {
    contacts: {
      menuName: 'Contactos',
      name: 'Contacto |||| Contactos',
      fields: {
        id: 'ID',
        fullName: 'Nombre completo',
        email: 'Dirección email',
        phoneNumber: 'Número telefonico',
        type: 'Tipo',
        processed: 'Procesado',
        createdAt: 'Creado en',
        updatedAt: 'Actualizado en'
      }
    },
    'waitlist-reasons': {
      name: 'Razón de lista espera |||| Razones de lista de espera',
      fields: {
        id: 'ID',
        code: 'Código',
        name: 'Nombre',
        createdAt: 'Creado en',
        updatedAt: 'Actualizado en'
      }
    },
    waitlist: {
      name: 'Lista de espera |||| Lista de espera',
      fields: {
        id: 'ID',
        email: 'Dirección email ',
        firstName: 'Primer nombre',
        middleName: 'Segundo nombre',
        lastName: 'Apellido',
        birthDate: 'Fecha de nacimiento',
        annualIncome: 'Ingresos anuales',
        state: 'Estado',
        city: 'Ciudad',
        neighborhood: 'Barrio',
        zipcode: 'Código zip',
        address: 'Dirección',
        processed: 'Procesado',
        waitlistReasonId: 'Razón',
        createdAt: 'Creado en',
        updatedAt: 'Actualizado en'
      }
    },
    'my-users': {
      menuName: 'Usuarios',
      name: 'Usuario |||| Usuarios',
      fields: {
        id: 'ID',
        email: 'Dirección email',
        firstName: 'Primer nombre',
        middleName: 'Segundo nombre',
        lastName: 'Apellido',
        authUid: 'ID de autentificación',
        active: 'Activo',
        createdAt: 'Creado en',
        updatedAt: 'Actualizado en'
      }
    },
    'my-roles': {
      menuName: 'Roles',
      name: 'Rol |||| Roles',
      fields: {
        id: 'ID',
        code: 'Código',
        name: 'Nombre',
        updatedAt: 'Actualizado en',
        processed: 'Processed'
      }
    },
    'assigned-roles': {
      menuName: 'Roles asignados',
      name: 'Rol asignado |||| Roles asignados',
      fields: {
        id: 'ID',
        roleId: 'Rol',
        userId: 'Usuario',
        updatedAt: 'Actualizado en',
        active: 'Activo'
      }
    },
    permissions: {
      menuName: 'Permisos',
      name: 'Permiso |||| Permisos',
      fields: {
        id: 'ID',
        modelName: 'Nombre del modelo',
        httpMethod: 'Método HTTP',
        action: 'Acción',
        allow: 'Permitir',
        roleId: 'Rol',
        updatedAt: 'Actualizado en'
      }
    },
    'clients-information': {
      menuName: 'Información de clientes',
      name: 'Información de cliente |||| Información de clientes',
      fields: {
        id: 'ID',
        DNI: 'DNI',
        birthDate: 'Fecha de nacimiento',
        annualIncome: 'Ingreso anual',
        userId: 'Usuario',
        createdAt: 'Creado en',
        updatedAt: 'Actualizado en'
      }
    },
    'property-types': {
      name: 'Tipo propiedad |||| Tipos de propiedades',
      fields: {
        id: 'ID',
        code: 'Código',
        name: 'Nombre',
        createdAt: 'Creado en',
        updatedAt: 'Actualizado en'
      }
    },
    houses: {
      name: 'Casa |||| Casas',
      fields: {
        id: 'ID',
        address: 'Dirección',
        neighborhood: 'Barrio',
        zillowPid: 'Zillow PID',
        zillowUseCode: 'Zillow tipo uso',
        zillowLinkHomeDetails: 'Zillow link detalles casa',
        zillowLastUpdated: 'Zillow ultima actualización',
        zillowEstimatedValue: 'Zillow valor estimado',
        operatedZillowEstimatedValue: 'Zillow valor estimado operado',
        zillowLowValuation: 'Zillow valuación baja',
        zillowHighValuation: 'Zillow valuación alta',
        zillowPercentile: 'Zillow percentil',
        zillowTaxAssessmentYear: 'Zillow año tasación impuestos',
        zillowTaxAssessment: 'Zillow tasación impuestos',
        cityId: 'Ciudad',
        propertyTypeId: 'Tipo propiedad',
        userId: 'Usuario',
        createdAt: 'Creado en',
        updatedAt: 'Actualizado en'
      },
      tabs: {
        house: 'Casa',
        zillow: 'Zillow'
      }
    },
    parameters: {
      menuName: 'Parametros',
      name: 'Parametro |||| Parametros',
      fields: {
        id: 'ID',
        name: 'Nombre',
        value: 'Valor',
        description: 'Descripción',
        createdAt: 'Creado en',
        updatedAt: 'Actualizado en'
      }
    },
    countries: {
      name: 'Pais |||| Pais',
      fields: {
        id: 'ID',
        name: 'Nombre',
        createdAt: 'Creado en',
        updatedAt: 'Actualizado en'
      }
    },
    states: {
      name: 'Estado |||| Estados',
      fields: {
        id: 'ID',
        name: 'Name',
        countryId: 'Pais',
        createdAt: 'Created at',
        updatedAt: 'Updated at'
      }
    },
    cities: {
      name: 'Ciudad |||| Ciudades',
      fields: {
        id: 'ID',
        name: 'Nombre',
        createdAt: 'Creado en',
        updatedAt: 'Actualizado en'
      }
    },
    'loan-requests': {
      name: 'Préstamos'
    },
    'loan-purposes': {
      name: 'Propósitos del préstamo'
    },
    'financing-configurations': {
      name: 'Financiación'
    },
    'loan-request-statuses': {
      name: 'Estados del préstamo'
    },
    'loan-types': {
      name: 'Tipos del préstamo'
    },
    'property-titles': {
      name: 'Título de propiedad'
    },
    companies: {
      name: 'Compañias'
    },
    investors: {
      name: 'Inversionistas'
    },
    subscriptions: {
      name: 'Suscripciones'
    },
    prequals: {
      name: 'Prequals'
    }
  }
};
