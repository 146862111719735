import React from 'react';
import {
  Edit,
  DisabledInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  TabbedForm,
  FormTab,
  required,
  REDUX_FORM_NAME
} from 'react-admin';
import { change } from 'redux-form';

import Tree from '../../components/Tree';
import ActionList, { redirect } from '../../utils/ActionList';
import EditToolbar from '../../utils/EditToolbar';

const Component = props => (
  <Edit {...props} actions={<ActionList />}>
    <TabbedForm submitOnEnter={false} redirect={redirect} toolbar={<EditToolbar />}>
      <FormTab label="Company info">
        <DisabledInput source="id" />
        <TextInput source="name" />
        <TextInput source="phoneNumber" />
        <TextInput source="address" />
        <TextInput source="zipcode" />
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => (
            <ReferenceInput
              source="userId"
              reference="assigned-roles"
              label="User"
              validate={required()}
              sort={{ order: 'ASC' }}
              filter={{ roleId: 3 }}
              onChange={() => dispatch(change(REDUX_FORM_NAME, 'userId', ''))}
              {...rest}
            >
              <SelectInput optionText="myUser.email" optionValue="userId" />
            </ReferenceInput>
          )}
        </FormDataConsumer>
      </FormTab>
      <FormTab label="Partner's">
        <Tree />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default Component;
