import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EmailField,
  Filter,
  TextInput,
  Responsive,
  SimpleList,
  EditButton,
  ShowButton,
  ReferenceField,
  NumberField
} from 'react-admin';

const Filters = props => (
  <Filter {...props}>
    <TextInput source="email" type="email" />
    <TextInput source="firstName" />
    <TextInput source="lastName" />
    <TextInput source="state" />
    <TextInput source="city" />
  </Filter>
);

export const Component = props => (
  <List {...props} filters={<Filters />} bulkActionButtons={false}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.email}
          secondaryText={record => record.firstName}
          tertiaryText={record => new Date(record.createdAt).toLocaleDateString()}
        />
      }
      medium={
        <Datagrid>
          <TextField source="id" />
          <EmailField source="email" />
          <TextField source="firstName" />
          <TextField source="lastName" />
          <ReferenceField source="waitlistReasonId" reference="waitlist-reasons">
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="annualIncome" />
          <TextField source="state" />
          <TextField source="city" />
          <ReferenceField source="waitlistStatusId" reference="waitlist-status">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" showTime />
          <ShowButton label="" />
          <EditButton label="" />
        </Datagrid>
      }
    />
  </List>
);

export default Component;
